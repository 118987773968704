import { ManageAccounts } from '@mui/icons-material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import CompanySettingsComponent from '../../../../components/company-settings/company-settings.component';
import PageTemplateComponent from '../../../../components/page-template/page-template.component';
import TabComponent, { TabElementType } from '../../../../components/tab/tab.component';
import { SettingsCompanyType, SettingsType } from '../../../../types/settings.type';
import { getSettings, updateCompanyDetails } from '../../../../utils/requests';
import SettingsPageStyled from './settings.page.styled';

const SettingsPage = () => {

    const [settings, setSettings] = useState<SettingsType>();
    const [isSettingsSyncing, setIsSettingsSyncing] = useState<boolean>(false);

    useEffect(
        () => {
            getSettings()
                .then(settings => {
                    setSettings(() => settings);
                })
                .catch((e: any) => {
                    toast.error(e.message);
                });
        },
        []
    )

    const onCompanyDetailsUpdate = useCallback(
        (updatedCompanyDetails: SettingsCompanyType) => {
            setIsSettingsSyncing(() => true);
            updateCompanyDetails(updatedCompanyDetails)
                .then(updatedSettings => {
                    setSettings(() => updatedSettings);
                    toast.success('Detaliile companiei au fost modificate cu succes');
                })
                .catch((e: any) => {
                    toast.error(e.message);
                })
                .finally(() => {
                    setIsSettingsSyncing(() => false);
                });
        },
        []
    )

    const tabs = useMemo(
        (): TabElementType[] => {
            return [
                {
                    headerTab: {
                        label: 'Detalii companie',
                        icon: <ManageAccounts />
                    },
                    panel: () => (
                        <CompanySettingsComponent 
                            companyDetails={settings?.company} 
                            onSave={onCompanyDetailsUpdate}
                            isLoading={isSettingsSyncing}
                        />
                    )
                }
            ]
        },
        [isSettingsSyncing, onCompanyDetailsUpdate, settings?.company]
    );


    /** define the return statement bellow */
    return (
        <SettingsPageStyled>
            <PageTemplateComponent title="Setari">
                <TabComponent tabs={tabs} />
            </PageTemplateComponent>
        </SettingsPageStyled>
    )

}

export default SettingsPage;