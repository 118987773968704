import { Add, Delete, Edit, History } from "@mui/icons-material";
import { Box, Checkbox, FormControlLabel, Grid, InputAdornment, ListItemButton, ListItemText } from "@mui/material";
import { isEmpty } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { HeaderTypeEnum } from "../../enums/header-type.enum";
import { PanelType } from "../../enums/panel-type.enum";
import { TableTypeEnum } from "../../enums/table-type.enum";
import { useStore } from "../../hooks/store.hook";
import { CustomerType } from "../../types/customer.type";
import { StateFieldType } from "../../types/form.types";
import { OrderProductType, ProductType } from "../../types/product.type";
import { createOrder, getCustomer, updateOrder } from "../../utils/requests";
import AsyncSelectComponent, { AsyncSelectOptionType } from "../async-select/async-select.component";
import ButtonComponent from "../button/button.component";
import FormSectionComponent from "../form-section/form-section.component";
import SelectOrderProductsComponent from "../select-order-products/select-order-products.component";
import StockSelectComponent from "../stock-select/stock-select.component";
import TableComponent, { ActionItemPropsType, CustomRendererPropsType, TableDataPropsType } from "../table/table.component";
import OrderPanelComponentStyled from "./order-panel.component.styled";
import TextFieldComponent from "../text-field/text-field.component";
import { getBillingOtherDetailsFromShipping, getKitPriceToDistribute, shortenString } from "../../utils/utils";
import { CreateOrderType, OrderType, UpdateOrderType, } from "../../types/order.type";
import SelectedListAddress from "../list-component/list-component.component";
import { themeVariables } from "../../theme/variables";
import { BillingAddressType, ShippingAddressType } from "../../types/address.type";
import OrderTotalFooterComponent from "../order-total-footer/order-total-footer.component";
import { ProductCategoryEnum } from "../../enums/product-category.enum";
import { ErrorCodeEnum } from '../../enums/error-code.enum';
import InssuficientStockErrorComponent from "../insufficient-stock-error/insufficient-stock-error.component";
import { CurrencyEnum } from "../../enums/currency.enum";
import { AddressTypeEnum } from "../../enums/address-type.enum";
import Address from "../address/address.component";
import CustomerPanelComponent from "../customer-panel/customer-panel.component";
import { getRequest } from "../../utils/http-requests";
import { v4 as uuid } from "uuid";
import CustomerDetailsComponent, { CustomerDetailsTab } from "../customer-details/customer-details.component";

type DataShippingPropsType = {
    shippingAddresses: ShippingAddressType[],
    selectedIndex: number,
}

type DataBillingPropsType = {
    billingAddresses: BillingAddressType[],
    selectedIndex: number,
}

type BillingAddressFormStateType = {
    fields: {
        firstName: StateFieldType<string>;
        lastName: StateFieldType<string>;
        phoneNumber: StateFieldType<string>;
        companyName: StateFieldType<string>;

        county: StateFieldType<string>;
        city: StateFieldType<string>;
        postalCode: StateFieldType<string>;

        vatCode: StateFieldType<string>;
        regCode: StateFieldType<string>;
        otherDetails: StateFieldType<string>;

        useShippingAsBilling: StateFieldType<boolean>;

    };
    shouldDisplayError: boolean;
}

type ShippingAddressFormStateType = {
    fields: {
        firstName: StateFieldType<string>;
        lastName: StateFieldType<string>;
        phoneNumber: StateFieldType<string>;
        companyName: StateFieldType<string>;

        county: StateFieldType<string>;
        city: StateFieldType<string>;
        postalCode: StateFieldType<string>;

        street: StateFieldType<string>;
        streetNumber: StateFieldType<string>;
        flat: StateFieldType<string>;
        building: StateFieldType<string>;
        floor: StateFieldType<string>;
        apartmentNumber: StateFieldType<string>;

        vatCode: StateFieldType<string>;
        regCode: StateFieldType<string>;

    };
    shouldDisplayError: boolean;
}

type OrderPanelComponentPropsType = {
    orderDetail?: OrderType
    customerDetail?: CustomerType
}

const OrderPanelComponent = ({
    orderDetail,
    customerDetail
}: OrderPanelComponentPropsType) => {

    /** inject mobx store inside  */
    const uiStore = useStore('uiStore');
    const tableStore = useStore('tableStore');
    // const userStore = useStore('userStore');

    const [selectedCustomer, setSelectedCustomer] = useState<CustomerType | null>();
    const [selectedProducts, setSelectedProducts] = useState<OrderProductType[]>([]);

    const [shippingAddress, setShippingAddress] = useState<ShippingAddressFormStateType>({
        fields: {
            firstName: {
                value: '',
                isValid: false,
                errorMessage: 'Furnizati un prenume valid'
            },
            lastName: {
                value: '',
                isValid: false,
                errorMessage: 'Furnizati un nume valid'
            },
            phoneNumber: {
                value: '',
                isValid: false,
                errorMessage: 'Furnizati un numar de telefon valid'
            },
            companyName: {
                value: '',
                isValid: false,
                noValidation: true,
            },
            city: {
                value: '',
                isValid: false,
            },
            county: {
                value: '',
                isValid: false,
            },
            postalCode: {
                value: '',
                isValid: true,
                noValidation: true,
            },
            street: {
                value: '',
                isValid: true,
                noValidation: true,
            },
            streetNumber: {
                value: '',
                isValid: true,
                noValidation: true,
            },
            flat: {
                value: '',
                isValid: true,
                noValidation: true,
            },
            building: {
                value: '',
                isValid: true,
                noValidation: true,
            },
            floor: {
                value: '',
                isValid: true,
                noValidation: true,
            },
            apartmentNumber: {
                value: '',
                isValid: true,
                noValidation: true,
            },
            vatCode: {
                value: '',
                isValid: true,
                noValidation: true,
            },
            regCode: {
                value: '',
                isValid: true,
                noValidation: true,
            },
        },
        shouldDisplayError: false
    })

    const [billingAddress, setBillingAddress] = useState<BillingAddressFormStateType>({
        fields: {
            firstName: {
                value: '',
                isValid: false,
                errorMessage: 'Furnizati un prenume valid'
            },
            lastName: {
                value: '',
                isValid: false,
                errorMessage: 'Furnizati un nume valid'
            },
            phoneNumber: {
                value: '',
                isValid: false,
                errorMessage: 'Furnizati un numar de telefon valid'
            },
            companyName: {
                value: '',
                isValid: false,
                noValidation: true,
            },
            city: {
                value: '',
                isValid: false,
            },
            county: {
                value: '',
                isValid: false,
            },
            postalCode: {
                value: '',
                isValid: true,
                noValidation: true,
            },
            vatCode: {
                value: '',
                isValid: true,
                noValidation: true,
            },
            regCode: {
                value: '',
                isValid: true,
                noValidation: true,
            },
            otherDetails: {
                value: '',
                isValid: true,
                noValidation: true,
            },
            useShippingAsBilling: {
                value: false,
                noValidation: true,
                isValid: true
            }
        },
        shouldDisplayError: false
    });

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selectedIndex, setSelectedIndex] = useState<number>(-1)
    const [selectedBillingIndex, setSelectedBillingIndex] = useState<number>(-1)

    const [selectedShippingAddress, setSelectedShippingAddress] = useState<ShippingAddressType>()
    const [selectedBillingAddress, setSelectedBillingAddress] = useState<BillingAddressType>()

    const [customersOptions, setCustomersOptions] = useState<AsyncSelectOptionType[]>([]);
    const [updateCustomerAfterCreate, setUpdateCustomerAfterCreate] = useState<boolean>(false)

    const [rerenderProductsTableKey, setRerenderProductsTableKey] = useState<string>('')

    const updateStateField = useCallback(
        (currentField: StateFieldType<any>, newValue: any): StateFieldType<any> => {
            return {
                ...currentField,
                isValid: (currentField.validator ? (currentField.validator as any)(newValue) : !!newValue) ||
                    currentField.noValidation,
                value: newValue
            }
        },
        []
    )

    useEffect(() => {
        if (orderDetail) {
            setSelectedCustomer(() => (orderDetail?.customer as CustomerType))
            const prod: OrderProductType[] = orderDetail.lineItems.map(m => ({
                id: m.product ? m.product : '',
                selectedStock: m.quantity,
                price: (m.price / m.quantity + m.discount).toString(),
                name: m.title,
                sku: m.sku,
                discount: (m.discount).toString(),
                category: m.category,
                childProducts: m.childrenItems?.map(childProduct => ({
                    id: childProduct?.id,
                    selectedStock: childProduct.quantity,
                    price: (childProduct.price / childProduct.quantity + childProduct.discount).toString(),
                    name: childProduct.title,
                    sku: childProduct.sku,
                    discount: (childProduct.discount).toString(),
                    category: childProduct.category,
                    stockTotal: (childProduct.quantity / m.quantity).toString(),
                    stockLines: [],
                    parentId: m.product
                })) as OrderProductType[],
                stockTotal: '0',
                stockLines: []
            }))
            setSelectedProducts(() => prod)
            setSelectedShippingAddress( () => orderDetail.shippingAddress)
            setSelectedBillingAddress( () => orderDetail.billingAddress)

        }
    },
    [
        updateStateField, 
        orderDetail,
    ]
    )

    useEffect(
        () => {
            if(customerDetail)  
                setSelectedCustomer(customerDetail)
        },
        [customerDetail]
    )

    const clearBillingAddressState = useCallback(() => {
        setBillingAddress(billingAddress => ({
            ...billingAddress,
            fields: {
                ...billingAddress.fields,
                firstName: updateStateField(billingAddress.fields.firstName, ''),
                lastName: updateStateField(billingAddress.fields.lastName, ''),
                phoneNumber: updateStateField(billingAddress.fields.phoneNumber, ''),
                companyName: updateStateField(billingAddress.fields.companyName, ''),
                city: updateStateField(billingAddress.fields.city, ''),
                county: updateStateField(billingAddress.fields.county, ''),
                postalCode: updateStateField(billingAddress.fields.postalCode, ''),
                otherDetails: updateStateField(billingAddress.fields.otherDetails, ''),
                vatCode: updateStateField(billingAddress.fields.vatCode, ''),
                regCode: updateStateField(billingAddress.fields.regCode, ''),
                useShippingAsBilling: updateStateField(billingAddress.fields.useShippingAsBilling, false)
            }
        }));
    },
        [updateStateField])
    
    useEffect(
        () => {
            if(!selectedCustomer) return

            setShippingAddress(shippingAddress => ({
                ...shippingAddress,
                fields: {
                    ...shippingAddress.fields,
                    firstName: updateStateField(shippingAddress.fields.firstName, selectedShippingAddress?.firstName ?? ''),
                    lastName: updateStateField(shippingAddress.fields.lastName, selectedShippingAddress?.lastName ?? ''),
                    phoneNumber: updateStateField(shippingAddress.fields.phoneNumber, selectedShippingAddress?.phoneNumber ?? ''),
                    companyName: updateStateField(shippingAddress.fields.companyName, selectedShippingAddress?.companyName ?? ''),
                    city: updateStateField(shippingAddress.fields.city, selectedShippingAddress?.city ?? ''),
                    county: updateStateField(shippingAddress.fields.county, selectedShippingAddress?.county ?? ''),
                    postalCode: updateStateField(shippingAddress.fields.postalCode, selectedShippingAddress?.postalCode ?? ''),
                    street: updateStateField(shippingAddress.fields.street, selectedShippingAddress?.street ?? ''),
                    streetNumber: updateStateField(shippingAddress.fields.streetNumber, selectedShippingAddress?.streetNumber ?? ''),
                    flat: updateStateField(shippingAddress.fields.flat, selectedShippingAddress?.flat ?? ''),
                    floor: updateStateField(shippingAddress.fields.floor, selectedShippingAddress?.floor ?? ''),
                    building: updateStateField(shippingAddress.fields.building, selectedShippingAddress?.building ?? ''),
                    apartmentNumber: updateStateField(shippingAddress.fields.apartmentNumber, selectedShippingAddress?.apartmentNumber ?? ''),
                    vatCode: updateStateField(shippingAddress.fields.vatCode, selectedShippingAddress?.vatCode ?? ''),
                    regCode: updateStateField(shippingAddress.fields.regCode, selectedShippingAddress?.regCode ?? ''),
                }
            }));
    
            setBillingAddress(billingAddress => ({
                ...billingAddress,
                fields: {
                    ...billingAddress.fields,
                    firstName: updateStateField(billingAddress.fields.firstName, selectedBillingAddress?.firstName ?? ''),
                    lastName: updateStateField(billingAddress.fields.lastName, selectedBillingAddress?.lastName ?? ''),
                    phoneNumber: updateStateField(billingAddress.fields.phoneNumber, selectedBillingAddress?.phoneNumber ?? ''),
                    companyName: updateStateField(billingAddress.fields.companyName, selectedBillingAddress?.companyName ?? ''),
                    city: updateStateField(billingAddress.fields.city, selectedBillingAddress?.city ?? ''),
                    county: updateStateField(billingAddress.fields.county, selectedBillingAddress?.county ?? ''),
                    vatCode: updateStateField(billingAddress.fields.vatCode, selectedBillingAddress?.vatCode ?? ''),
                    regCode: updateStateField(billingAddress.fields.regCode, selectedBillingAddress?.regCode ?? ''),
                    otherDetails: updateStateField(billingAddress.fields.otherDetails, selectedBillingAddress?.otherDetails),
                    useShippingAsBilling: updateStateField(billingAddress.fields.otherDetails, selectedBillingAddress?.useShippingAsBilling?? false)
                }
            }));
        },
        [
            updateStateField, 
            selectedShippingAddress, 
            selectedBillingAddress, 
            selectedCustomer
        ]
    )
    useEffect(
        () => {
            if(billingAddress.fields.useShippingAsBilling.value){
                setBillingAddress(billingAddress => ({
                    ...billingAddress,
                    fields: {
                        ...billingAddress.fields,
                        firstName: updateStateField(billingAddress.fields.firstName, selectedShippingAddress?.firstName ?? ''),
                        lastName: updateStateField(billingAddress.fields.lastName, selectedShippingAddress?.lastName ?? ''),
                        phoneNumber: updateStateField(billingAddress.fields.phoneNumber, selectedShippingAddress?.phoneNumber ?? ''),
                        companyName: updateStateField(billingAddress.fields.companyName, selectedShippingAddress?.companyName ?? ''),
                        city: updateStateField(billingAddress.fields.city, selectedShippingAddress?.city ?? ''),
                        county: updateStateField(billingAddress.fields.county, selectedShippingAddress?.county ?? ''),
                        postalCode: updateStateField(billingAddress.fields.postalCode, selectedShippingAddress?.postalCode ?? ''),
                        otherDetails: updateStateField(billingAddress.fields.otherDetails, getBillingOtherDetailsFromShipping({
                            street: selectedShippingAddress?.street ?? ' ',
                            streetNumber: selectedShippingAddress?.streetNumber ?? ' ',
                            flat: selectedShippingAddress?.flat ?? '',
                            building: selectedShippingAddress?.building ?? '',
                            floor: selectedShippingAddress?.floor ?? '',
                            apartmentNumber: selectedShippingAddress?.apartmentNumber ?? ''
                        })),
                        vatCode: updateStateField(billingAddress.fields.vatCode, selectedShippingAddress?.vatCode ?? ''),
                        regCode: updateStateField(billingAddress.fields.regCode, selectedShippingAddress?.regCode ?? ''),
                        useShippingAsBilling: updateStateField(billingAddress.fields.regCode, true),
                    }
                }));

                setSelectedBillingAddress( () => ({
                    city: selectedShippingAddress?.city ?? '',
                    companyName: selectedShippingAddress?.companyName ?? '',
                    county: selectedShippingAddress?.county ?? '',
                    firstName: selectedShippingAddress?.firstName ?? '',
                    lastName: selectedShippingAddress?.lastName ?? '',
                    otherDetails: getBillingOtherDetailsFromShipping({
                        street: selectedShippingAddress?.street ?? ' ',
                        streetNumber: selectedShippingAddress?.streetNumber ?? ' ',
                        flat: selectedShippingAddress?.flat ?? '',
                        building: selectedShippingAddress?.building ?? '',
                        floor: selectedShippingAddress?.floor ?? '',
                        apartmentNumber: selectedShippingAddress?.apartmentNumber ?? ''
                    }),
                    phoneNumber: selectedShippingAddress?.phoneNumber ?? '',
                    regCode: selectedShippingAddress?.regCode ?? '',
                    vatCode: selectedShippingAddress?.vatCode ?? '',
                    useShippingAsBilling: true,
                }))

            }
        },
        [  
            updateStateField,
            selectedShippingAddress,
            billingAddress.fields.useShippingAsBilling,
        ]
    )
    useEffect(
        () => {
            async function getCustomers() {
                const urlCustomers = "/customer/get-list"
                const response = await getRequest({url: urlCustomers})
                setCustomersOptions(response.data)
            }
            getCustomers()
            setUpdateCustomerAfterCreate(false)
        },
        [updateCustomerAfterCreate]
    )
    useEffect(
        () => {
            if(selectedCustomer?.id
                || selectedProducts.length
                || selectedShippingAddress?.id
                || selectedBillingAddress?.id)
                uiStore.setOrderData(true)
            else
                uiStore.setOrderData(false)
        },
        [
            selectedBillingAddress?.id, 
            selectedCustomer?.id, 
            selectedProducts.length, 
            selectedShippingAddress?.id, 
            uiStore
        ]
    )

    const onCustomerSelected = useCallback(
        async (customer: AsyncSelectOptionType | null) => {
            let fetchedCustomer: CustomerType | undefined;

            if (customer === null) {
            } else {
                try {
                    fetchedCustomer = await getCustomer(customer.id ?? '');
                } catch (e: any) {
                    toast.error(e.message);
                }
            }

            setSelectedCustomer(() => fetchedCustomer ?? null);

            setBillingAddress(billingAddress => ({
                ...billingAddress,
                fields: {
                    ...billingAddress.fields,
                    firstName: updateStateField(billingAddress.fields.firstName, ''),
                    lastName: updateStateField(billingAddress.fields.lastName, ''),
                    phoneNumber: updateStateField(billingAddress.fields.phoneNumber, ''),
                    companyName: updateStateField(billingAddress.fields.companyName, ''),
                    county: updateStateField(billingAddress.fields.county, ''),
                    city: updateStateField(billingAddress.fields.city, ''),
                    otherDetails: updateStateField(billingAddress.fields.otherDetails, ''),
                    useShippingAsBilling: updateStateField(billingAddress.fields.useShippingAsBilling, false)
                }
            }));

            setShippingAddress(shippingAddress => ({
                ...shippingAddress,
                fields: {
                    ...shippingAddress.fields,
                    firstName: updateStateField(shippingAddress.fields.firstName, ''),
                    lastName: updateStateField(shippingAddress.fields.lastName, ''),
                    phoneNumber: updateStateField(shippingAddress.fields.phoneNumber, ''),
                    companyName: updateStateField(shippingAddress.fields.companyName, ''),
                    county: updateStateField(shippingAddress.fields.county, ''),
                    city: updateStateField(shippingAddress.fields.city, ''),
                    postalCode: updateStateField(shippingAddress.fields.postalCode, ''),
                    street: updateStateField(shippingAddress.fields.street, ''),
                    streetNumber: updateStateField(shippingAddress.fields.streetNumber, ''),
                    flat: updateStateField(shippingAddress.fields.flat, ''),
                    floor: updateStateField(shippingAddress.fields.floor, ''),
                    building: updateStateField(shippingAddress.fields.building, ''),
                    apartmentNumber: updateStateField(shippingAddress.fields.apartmentNumber, ''),
                    vatCode: updateStateField(shippingAddress.fields.vatCode, ''),
                    regCode: updateStateField(shippingAddress.fields.regCode, ''),
                }
            }));

            setSelectedBillingAddress( () => ({
                city: '',
                companyName: '',
                county: '',
                firstName: '',
                lastName: '',
                otherDetails: '',
                phoneNumber: '',
                regCode:'',
                vatCode: '',
                useShippingAsBilling: false
            }))

            setSelectedShippingAddress( () => ({
                city: '',
                companyName: '',
                county: '',
                firstName: '',
                lastName: '',
                otherDetails: '',
                phoneNumber: '',
                regCode:'',
                vatCode: '',
                apartmentNumber: '',
                building: '',
                flat: '',
                floor: '',
                postalCode: '',
                street: '',
                streetNumber: ''
            }))

        },
        [updateStateField]
    )

    const onChooseProducts = useCallback(
        () => {
            uiStore.openPanel({
                key: PanelType.AddProduct,
                title: "Selecteaza produsele",
                panelWidth: '650px',
                component: <SelectOrderProductsComponent preselectedItems={selectedProducts} />,
                onDismiss: (data) => {
                    if (!data) return;

                    setRerenderProductsTableKey(uuid());

                    setSelectedProducts(selectedProducts => (data as ProductType[])
                        .map(prod => {
                            const selectedProd = selectedProducts.find(f => f.id === prod.id);
                            if (selectedProd) return selectedProd;

                            const parsedProduct = {
                                ...prod,
                                selectedStock: 1,
                                discount: '0',
                                childProducts: prod.childProducts?.map(childProduct => ({
                                    ...(childProduct.product as ProductType),
                                    selectedStock: childProduct.quantity,
                                    stockTotal: childProduct.quantity.toString(),
                                    parentId: prod.id,
                                    discount: '0',
                                }) as OrderProductType)
                            }

                            return {
                                ...parsedProduct,
                                childProducts: parsedProduct.childProducts?.map(childProduct => ({
                                    ...childProduct,
                                    discount: getKitPriceToDistribute(parsedProduct, 1).toString()
                                }))
                            }

                        })
                    );
                }
            })
        },
        [uiStore, selectedProducts]
    )

    const createClient = useCallback(
        () => {
            uiStore.openPanel({
                key: PanelType.Customer,
                title: "Adaugare client",
                component: <CustomerPanelComponent />,
                onDismiss: (data) => {
                    if (!data) return;
                    setUpdateCustomerAfterCreate(true)
                }
            })
        },
        [uiStore]
    )

    const customRenderer = useMemo(
        (): CustomRendererPropsType => {
            return {
                name: (row: OrderProductType) => {
                    return (
                        <>
                            <div className="product-sku">{row.sku}</div>
                            <div className="product-name">{shortenString(row.name, 18)}</div>
                        </>
                    )
                },
                price: (row: OrderProductType) => {
                    return (
                        <StockSelectComponent
                            size="small"
                            variant="standard"
                            value={(row.selectedStock ?? '1').toString()}
                            maxWidth='100px'
                            onTextChange={newValue => setSelectedProducts(selectedProducts =>
                                selectedProducts.map(prod => {
                                    if (prod.id === row.id) {
                                        if (row.category === ProductCategoryEnum.Kit) {
                                            return {
                                                ...prod,
                                                selectedStock: +newValue,
                                                childProducts: prod.childProducts?.map(childProd => ({
                                                    ...childProd,
                                                    selectedStock: +newValue * +childProd.stockTotal,
                                                    discount: getKitPriceToDistribute(prod, +newValue).toString()
                                                }))
                                            }
                                        } else {
                                            return { ...prod, selectedStock: + newValue };
                                        }
                                    }

                                    return prod;
                                })
                            )}
                        />
                    )
                },
                totalPrice: (row: OrderProductType) => {
                    return (
                        <div className="total-price-wrapper">
                            {
                                +row.discount > 0 ? (
                                    <div className="price-without-discount">
                                        <div className="line-throught"></div>
                                        {(parseFloat(row.price) * row.selectedStock).toFixed(2)} RON
                                    </div>
                                ) :
                                    ''
                            }

                            <div>
                                {((+row.price - (+row.discount)) * row.selectedStock).toFixed(2)} RON
                            </div>
                        </div>
                    )
                }
            }
        },
        []
    )

    const childProductsCustomerRenderer = useMemo(
        (): CustomRendererPropsType => {
            return {
                name: (row: OrderProductType) => {
                    return (
                        <>
                            <div className="product-sku">{row.sku}</div>
                            <div className="product-name">{shortenString(row.name, 18)}</div>
                        </>
                    )
                },
                totalPrice: (row: OrderProductType) => {
                    return (
                        <div className="total-price-wrapper child-wrapper">
                            {
                                +row.discount > 0 ? (
                                    <div className="price-without-discount">
                                        <div className="line-throught"></div>
                                        {(parseFloat(row.price) * row.selectedStock).toFixed(2)} {CurrencyEnum.RON}
                                    </div>
                                ) :
                                    ''
                            }

                            <div>
                                {((+row.price - (+row.discount)) * row.selectedStock).toFixed(2)} {CurrencyEnum.RON}
                            </div>
                        </div>
                    )
                }
            }
        },
        []
    )

    const tableData = useMemo(
        (): TableDataPropsType => {
            return {
                data: selectedProducts,
                headers: [
                    {
                        id: 'name',
                        label: 'Nume',
                        alignment: 'left',
                        sortable: false,
                        headerType: HeaderTypeEnum.String
                    },
                    {
                        id: 'price',
                        label: 'Cantitate',
                        alignment: 'right',
                        sortable: false,
                        headerType: HeaderTypeEnum.String
                    },
                    {
                        id: 'totalPrice',
                        label: 'Pret total',
                        alignment: 'right',
                        sortable: false,
                        headerType: HeaderTypeEnum.String
                    },
                ]
            }
        },
        [selectedProducts]
    )

    // const saveNewAddressOnClient = useCallback(
    //     async (customer: CustomerType) => {
    //         const data: UpdateCustomerType = {
    //             email: selectedCustomer?.email,
    //             phoneNumber: selectedCustomer?.phoneNumber,
    //             firstName: selectedCustomer?.firstName,
    //             lastName: selectedCustomer?.lastName,
    //             shopifyId: selectedCustomer?.shopifyId,
    //             company: {
    //                 name: selectedCustomer?.company?.name,
    //                 address: selectedCustomer?.company?.address,
    //                 vatCode: selectedCustomer?.company?.vatCode,
    //                 regCode: selectedCustomer?.company?.regCode,
    //             },
    //             shippingAddress: selectedCustomer?.shippingAddress?.map(m => ({
    //                 firstName: m.firstName,
    //                 lastName: m.lastName,
    //                 phoneNumber: m.phoneNumber,
    //                 companyName: m.companyName,
    //                 street: m.street,
    //                 streetNumber: m.streetNumber,
    //                 flat: m.flat,
    //                 building: m.building,
    //                 floor: m.floor,
    //                 apartmentNumber: m.apartmentNumber,
    //                 city: m.city,
    //                 county: m.county,
    //                 postalCode: m.postalCode,
    //                 vatCode: m.vatCode,
    //                 regCode: m.regCode,
    //             })),
    //             billingAddress: selectedCustomer?.billingAddress?.map(m => ({
    //                 firstName: m.firstName,
    //                 lastName: m.lastName,
    //                 phoneNumber: m.phoneNumber,
    //                 companyName: m.companyName,
    //                 city: m.city,
    //                 county: m.county,
    //                 vatCode: m.vatCode,
    //                 regCode: m.regCode,
    //                 otherDetails: m.otherDetails,
    //             })),
    //         }

    //         try {
    //             if (selectedCustomer)
    //                 await updateCustomer(selectedCustomer.id, data);
    //         } catch (e: any) {
    //             toast.error(() => e.message);
    //         }
    //     },
    //     [selectedCustomer]
    // )

    const orderAction = useCallback(
        async () => {
            setShippingAddress(state => ({
                ...state,
                shouldDisplayError: true
            }));
            setBillingAddress(state => ({
                ...state,
                shouldDisplayError: true
            }))

            if(
                !selectedProducts.length || 
                !selectedCustomer?.email ||
                !shippingAddress.fields.street.value ||
                !billingAddress.fields.city.value
            ){
                if(!selectedProducts.length)
                    toast.error('Nu aveti produse selectate')
                if(!selectedCustomer?.email)
                    toast.error('Nu aveti niciun client selectat')
                else {
                    if(!shippingAddress.fields.street.value)
                        toast.error('Nu ati selectat nicio adresa de livrare')
                    if(!billingAddress.fields.city.value)
                        toast.error('Nu ati selectat nicio adresa de facturare')
                }

                return
            }

            var orderTotalPrice: number = selectedProducts.reduce(
                (prev: number, curr: OrderProductType) => {
                    return prev + parseFloat(curr?.price) * curr.selectedStock
                },
                0
            )

            var data: Partial<CreateOrderType> | Partial<UpdateOrderType> = {
                shopifyId: selectedCustomer?.shopifyId,
                customer: selectedCustomer?.id ?? '',
                totalPrice: orderTotalPrice,
                lineItems: selectedProducts.map(product => ({
                    id: product.id,
                    title: product.name,
                    sku: product.sku,
                    price: (+product.price - +product.discount) * product.selectedStock,
                    quantity: product.selectedStock,
                    discount: +product.discount,
                    unitPrice: +product.price,
                    tax: 0,
                    childrenItems: product.childProducts?.map(childProduct => ({
                        id: childProduct.id,
                        title: childProduct.name,
                        sku: childProduct.sku,
                        price: (+childProduct.price - +childProduct.discount) * childProduct?.selectedStock,
                        quantity: childProduct.selectedStock,
                        discount: +childProduct.discount,
                        unitPrice: +childProduct.price,
                        standaloneProductId: childProduct.standaloneProductId,
                        tax: 0,
                        category: childProduct.category,
                        product: childProduct.id
                    })),
                    product: product.id,
                    category: product.category
                })),
                shippingAddress: {
                    firstName: shippingAddress.fields.firstName.value,
                    lastName: shippingAddress.fields.lastName.value,
                    phoneNumber: shippingAddress.fields.phoneNumber.value,
                    companyName: shippingAddress.fields.companyName.value,
                    postalCode: shippingAddress.fields.postalCode.value,
                    city: shippingAddress.fields.city.value,
                    county: shippingAddress.fields.county.value,
                    street: shippingAddress.fields.street.value,
                    streetNumber: shippingAddress.fields.streetNumber.value,
                    flat: shippingAddress.fields.flat.value,
                    building: shippingAddress.fields.building.value,
                    floor: shippingAddress.fields.floor.value,
                    vatCode: shippingAddress.fields.vatCode.value,
                    regCode: shippingAddress.fields.regCode.value,
                    apartmentNumber: shippingAddress.fields.apartmentNumber.value,
                },
                billingAddress: {
                    city: billingAddress.fields.city.value,
                    county: billingAddress.fields.county.value,
                    firstName: billingAddress.fields.firstName.value,
                    lastName: billingAddress.fields.lastName.value,
                    phoneNumber: billingAddress.fields.phoneNumber.value,
                    companyName: billingAddress.fields.companyName.value,
                    vatCode: billingAddress.fields.vatCode.value,
                    regCode: billingAddress.fields.regCode.value,
                    otherDetails: billingAddress.fields.otherDetails.value,
                    useShippingAsBilling: billingAddress.fields.useShippingAsBilling.value
                }
            }

            if (orderDetail) {
                data = {
                    ...data,
                    payment: orderDetail.payment,
                    status: orderDetail.status,
                    shopifyId: orderDetail.shopifyId,
                    source: orderDetail.source,
                    orderId: orderDetail.orderId,
                    awbs: orderDetail.awbs,
                    shippingLines: orderDetail.shippingLines,
                    id: orderDetail.id
                }
            }

            setIsLoading(() => true);

            try {
                if (orderDetail) {
                    await updateOrder(orderDetail.id, data)
                    uiStore.dismissPanel({
                        data: {
                            ...data,
                            customer: orderDetail.customer
                        }
                    })
                    toast.success('Comanda a fost modificata cu succes');
                }
                else {
                    await createOrder(data)
                    uiStore.dismissPanel();
                    toast.success('Comanda a fost salvata cu succes');
                }
                tableStore.updateTable(TableTypeEnum.Orders)
                tableStore.updateTable(TableTypeEnum.Products)

            } catch (e: any) {
                if (e.errorCode === ErrorCodeEnum.InsufficientStock) {
                    uiStore.openModal({
                        component: <InssuficientStockErrorComponent data={e.additionalData} />,
                        title: 'Stoc insuficient'
                    })
                } else {
                    toast.error(() => e.message);
                }
            }

            setIsLoading(() => false);
        },
        [
            selectedCustomer,
            selectedProducts,
            shippingAddress.fields.firstName.value,
            shippingAddress.fields.lastName.value,
            shippingAddress.fields.phoneNumber.value,
            shippingAddress.fields.companyName.value,
            shippingAddress.fields.postalCode.value,
            shippingAddress.fields.city.value,
            shippingAddress.fields.county.value,
            shippingAddress.fields.street.value,
            shippingAddress.fields.streetNumber.value,
            shippingAddress.fields.flat.value,
            shippingAddress.fields.building.value,
            shippingAddress.fields.floor.value,
            shippingAddress.fields.vatCode.value,
            shippingAddress.fields.regCode.value,
            shippingAddress.fields.apartmentNumber.value,
            billingAddress.fields.city.value,
            billingAddress.fields.county.value,
            billingAddress.fields.firstName.value,
            billingAddress.fields.lastName.value,
            billingAddress.fields.phoneNumber.value,
            billingAddress.fields.companyName.value,
            billingAddress.fields.vatCode.value,
            billingAddress.fields.regCode.value,
            billingAddress.fields.otherDetails.value,
            billingAddress.fields.useShippingAsBilling.value,
            orderDetail,
            tableStore,
            uiStore
        ]
    )

    const totalPrice = useMemo(
        (): number => {
            if (isEmpty(selectedProducts)) return 0;

            return selectedProducts.reduce(
                (prev: number, curr: OrderProductType) => {
                    return prev + parseFloat(curr?.price) * curr.selectedStock
                },
                0
            )
        },
        [selectedProducts]
    )

    const totalDiscount = useMemo(
        (): number => {
            if (isEmpty(selectedProducts)) return 0;

            return selectedProducts.reduce(
                (prev: number, curr: OrderProductType) => {
                    return prev + parseFloat(curr?.discount) * curr.selectedStock
                },
                0
            )
        },
        [selectedProducts]
    )

    const kitTableData = useCallback(
        (product: OrderProductType): TableDataPropsType => {
            return {
                data: product.childProducts ?? [],
                headers: [
                    {
                        id: 'name',
                        label: 'Nume',
                        alignment: 'left',
                        sortable: false,
                        headerType: HeaderTypeEnum.String
                    },
                    {
                        id: 'selectedStock',
                        label: 'Cantitate',
                        alignment: 'right',
                        sortable: false,
                        headerType: HeaderTypeEnum.String
                    },
                    {
                        id: 'totalPrice',
                        label: 'Pret total',
                        alignment: 'right',
                        sortable: false,
                        headerType: HeaderTypeEnum.String
                    },
                ]
            }
        },
        []
    )

    const renderExpandable = useCallback(
        (row: OrderProductType) => {
            return (
                <div className="expanded-row">
                    {
                        isEmpty(row.childProducts) ?
                            <Grid container spacing={2} style={{ padding: '0 16px' }}>
                                <Grid item xs={6}>
                                    <TextFieldComponent
                                        type='number'
                                        label="Pret unitar"
                                        size="small"
                                        variant="outlined"
                                        fullWidth
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">{CurrencyEnum.RON}</InputAdornment>
                                        }}
                                        value={row.price}
                                        onTextChange={newValue => setSelectedProducts(selectedProducts =>
                                            selectedProducts.map(prod => {
                                                if (row.parentId && prod.id === row.parentId) {
                                                    let updatedProduct = {
                                                        ...prod,
                                                        childProducts: prod.childProducts?.map(childProd => {
                                                            if (childProd.id === row.id) {
                                                                return { ...childProd, price: newValue };
                                                            } else
                                                                return childProd;
                                                        })
                                                    }

                                                    const totalChildrenPrice = (updatedProduct.childProducts || []).reduce((prev, curr) => {
                                                        return prev + ((+curr.price - +curr.discount) * +curr.stockTotal);
                                                    }, 0);

                                                    return {
                                                        ...updatedProduct,
                                                        price: totalChildrenPrice.toString()
                                                    }
                                                } else if (prod.id === row.id)
                                                    return { ...prod, price: newValue };
                                                return prod;
                                            })
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextFieldComponent
                                        type='number'
                                        label="Discount"
                                        size="small"
                                        variant="outlined"
                                        fullWidth
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">{CurrencyEnum.RON}</InputAdornment>
                                        }}
                                        value={row.discount}
                                        onTextChange={newValue => setSelectedProducts(selectedProducts =>
                                            selectedProducts.map(prod => {
                                                if (row.parentId && prod.id === row.parentId) {
                                                    let updatedProduct = {
                                                        ...prod,
                                                        childProducts: prod.childProducts?.map(childProd => {
                                                            if (childProd.id === row.id) {
                                                                return { ...childProd, discount: newValue };
                                                            } else
                                                                return childProd;
                                                        })
                                                    }

                                                    const totalChildrenPrice = (updatedProduct.childProducts || []).reduce((prev, curr) => {
                                                        return prev + ((+curr.price - +curr.discount) * +curr.stockTotal);
                                                    }, 0);

                                                    return {
                                                        ...updatedProduct,
                                                        price: totalChildrenPrice.toString()
                                                    }
                                                } else if (prod.id === row.id)
                                                    return { ...prod, discount: newValue };
                                                return prod;
                                            })
                                        )}
                                    />
                                </Grid>
                            </Grid> :
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TableComponent
                                        tableKey={TableTypeEnum.OrderProducts}
                                        withoutSelect
                                        viewType='minimal'
                                        withoutPersist
                                        withoutPagination
                                        withoutSearchBar
                                        withoutDenseSwitch
                                        customRenderer={childProductsCustomerRenderer}
                                        tableDataChild={kitTableData(row)}
                                        expandableRenderer={renderExpandable}
                                        denseByDefault
                                    />
                                </Grid>
                            </Grid>
                    }

                </div>
            )
        },
        [childProductsCustomerRenderer, kitTableData]
    )

    var actionItems = useMemo(
        (): ActionItemPropsType[] => [
            {
                text: "Sterge",
                icon: <Delete />,
                color: "black",
                fOnClick: (row: OrderProductType) => {
                    setSelectedProducts(selectedProducts => selectedProducts.filter(f => f.id !== row.id));
                }
            },
        ],
        []
    )

    const generateTableForSelectedProducts = useCallback(
        () => {
            if (selectedProducts.length > 0)
                return (
                    <Grid item xs={12}>
                        <TableComponent
                            key={rerenderProductsTableKey}
                            tableKey={TableTypeEnum.OrderProducts}
                            withoutSelect
                            searchBarPlaceholder="Cauta dupa nume sau SKU"
                            viewType='panel'
                            withoutDenseSwitch
                            customRenderer={customRenderer}
                            tableData={tableData}
                            expandableRenderer={renderExpandable}
                            denseByDefault
                            actionItems={actionItems}
                            withoutPersist
                            footerRenderer={props => <OrderTotalFooterComponent {...props} total={totalPrice} discountTotal={totalDiscount} withDivider />}
                        />
                    </Grid>
                )
            else
                return ''
        },
        [actionItems, customRenderer, renderExpandable, selectedProducts.length, tableData, totalDiscount, totalPrice, rerenderProductsTableKey]
    )

    const onSameAddressCheckboxChange = useCallback(
        (isSelected: boolean) => {
            if (isSelected) {

                setBillingAddress(billingAddress => ({
                    ...billingAddress,
                    fields: {
                        ...billingAddress.fields,
                        firstName: updateStateField(billingAddress.fields.firstName, shippingAddress.fields.firstName.value ?? ''),
                        lastName: updateStateField(billingAddress.fields.lastName, shippingAddress.fields.lastName.value ?? ''),
                        phoneNumber: updateStateField(billingAddress.fields.phoneNumber, shippingAddress.fields.phoneNumber.value ?? ''),
                        companyName: updateStateField(billingAddress.fields.companyName, shippingAddress.fields.companyName.value ?? ''),
                        city: updateStateField(billingAddress.fields.city, shippingAddress.fields.city.value ?? ''),
                        county: updateStateField(billingAddress.fields.county, shippingAddress.fields.county.value ?? ''),
                        postalCode: updateStateField(billingAddress.fields.postalCode, shippingAddress.fields.postalCode.value ?? ''),
                        otherDetails: updateStateField(billingAddress.fields.otherDetails, getBillingOtherDetailsFromShipping({
                            street: shippingAddress.fields.street.value ?? '',
                            streetNumber: shippingAddress.fields.streetNumber.value ?? '',
                            flat: shippingAddress.fields.flat.value ?? '',
                            building: shippingAddress.fields.building.value ?? '',
                            floor: shippingAddress.fields.floor.value ?? '',
                            apartmentNumber: shippingAddress.fields.apartmentNumber.value ?? ''
                        })),
                        vatCode: updateStateField(billingAddress.fields.vatCode, shippingAddress.fields.vatCode.value ?? ''),
                        regCode: updateStateField(billingAddress.fields.regCode, shippingAddress.fields.regCode.value ?? ''),
                        useShippingAsBilling: updateStateField(billingAddress.fields.useShippingAsBilling, true)
                    }
                }));

                setSelectedBillingAddress( (state) => ({
                    ...state,
                    useShippingAsBilling: true
                }))
            } else {
                clearBillingAddressState();
                setSelectedBillingAddress( () => ({
                    city: '',
                    companyName: '',
                    county: '',
                    firstName: '',
                    lastName: '',
                    otherDetails: '',
                    phoneNumber: '',
                    regCode:'',
                    vatCode: '',
                    useShippingAsBilling: false
                }))
            }
        },
        [
            shippingAddress.fields.apartmentNumber.value,
            shippingAddress.fields.building.value,
            shippingAddress.fields.city.value,
            shippingAddress.fields.companyName.value,
            shippingAddress.fields.county.value,
            shippingAddress.fields.firstName.value,
            shippingAddress.fields.flat.value,
            shippingAddress.fields.floor.value,
            shippingAddress.fields.lastName.value,
            shippingAddress.fields.phoneNumber.value,
            shippingAddress.fields.postalCode.value,
            shippingAddress.fields.regCode.value,
            shippingAddress.fields.street.value,
            shippingAddress.fields.streetNumber.value,
            shippingAddress.fields.vatCode.value,
            updateStateField,
            clearBillingAddressState
        ]
    )
    const selectShippingAddress = useCallback(
        () => {
            uiStore.openPanel({
                key: PanelType.EditAddress,
                title: "Selecteaza adresa livrare",
                component: <SelectedListAddress isShipping={true} customer={selectedCustomer} shippingAddresses={selectedCustomer?.shippingAddress} order={true} selectIndex={selectedIndex} />,
                onDismiss: (data: DataShippingPropsType) => {
                    if (!data) return
                    
                    setSelectedShippingAddress(() => data.shippingAddresses[data.selectedIndex])
                    setSelectedIndex(() => data.selectedIndex)
                }
            })
        },
        [
            selectedCustomer, 
            uiStore, 
            selectedIndex
        ]
    )

    const selectBillingAddress = useCallback(
        () => {
            uiStore.openPanel({
                key: PanelType.EditAddress,
                title: "Selecteaza adresa facturare",
                component: <SelectedListAddress isShipping={false} customer={selectedCustomer} billingAddresses={selectedCustomer?.billingAddress} order={true} selectIndex={selectedBillingIndex} />,
                onDismiss: (data: DataBillingPropsType) => {
                    if (!data) return;

                    setSelectedBillingAddress(() => data.billingAddresses[data.selectedIndex])
                    setSelectedBillingIndex(() => data.selectedIndex)
                }
            })
        },
        [
            uiStore,
            selectedBillingIndex,
            selectedCustomer
        ]
    )

    const handleShippingAddressClick = useCallback(
        () => {
            uiStore.openPanel({
                key: PanelType.EditAddress,
                title: "Editeaza adresa de livrare",
                component: <Address type={AddressTypeEnum.Shipping} editShippingAddressInfo={selectedShippingAddress} />,
                onDismiss: async (data: ShippingAddressType) => {
                    if (!data) return;
                    setSelectedShippingAddress(() => data);
                }
            })
        },
        [
            uiStore,
            selectedShippingAddress
        ]
    )

    const handleBillingAddressClick = useCallback(
        () => {
            if (!selectedBillingAddress) return;

            uiStore.openPanel({
                key: PanelType.EditAddress,
                title: "Editeaza adresa de facturare",
                component: <Address type={AddressTypeEnum.Billing} editBillingAddressInfo={selectedBillingAddress} />,
                onDismiss: async (data: BillingAddressType) => {
                    if (!data) return;
                    var billingIsEdited: number = 0
                    // eslint-disable-next-line array-callback-return
                    Object.values(data).map( (m : string, index) => {
                        if(Object.values(selectedBillingAddress)[index] !== m)
                            billingIsEdited = 1 
                    })

                    if(billingIsEdited === 1){
                        setSelectedBillingAddress(() => ({...data, useShippingAsBilling: false}));
                        setBillingAddress(state => ({
                            ...state,
                            fields:{
                                ...state.fields,
                                useShippingAsBilling: updateStateField(billingAddress.fields.useShippingAsBilling, false)
                            }
                        }))
                    } else {
                        setSelectedBillingAddress(() => data)
                    }
                    
                }
            })
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            selectedBillingAddress, 
            uiStore, 
            updateStateField, 
        ]
    )

    const openClientActionLog = useCallback(
        (customer: CustomerType)=> {
            uiStore.openPanel({
                key: PanelType.ViewClientHistory,
                title: `${customer.fullName}`,
                component: <CustomerDetailsComponent 
                                customerId={customer.id} 
                                tabFocused={CustomerDetailsTab.ActionLog} 
                                title={customer.firstName !== undefined && customer.fullName !== undefined ? customer.fullName : customer.email} 
                            />,
            });
        },
        [uiStore]
    )

    const openClientEditDetails = useCallback(
        (customer: CustomerType)=> {
            uiStore.openPanel({
                key: PanelType.EditUser,
                title: `${customer.fullName}`,
                component: <CustomerDetailsComponent 
                                customerId={customer.id} 
                                tabFocused={CustomerDetailsTab.CustomerDetails} 
                                title={customer.firstName !== undefined && customer.fullName !== undefined ? customer.fullName : customer.email} 
                            />,
            });
        },
        [uiStore]
    )

    return (
        <OrderPanelComponentStyled>
            <FormSectionComponent variant="panel" title="Produse">
                <Grid container spacing={2}>
                    {
                        generateTableForSelectedProducts()
                    }

                    <Grid item xs={12}>
                        <ButtonComponent
                            variant="text"
                            onClick={onChooseProducts}
                            size="medium"
                            startIcon={<Add />}
                        >
                            Selecteaza produse
                        </ButtonComponent>
                    </Grid>
                </Grid>
            </FormSectionComponent>

            <FormSectionComponent variant="panel" title="Client">
                <Grid item xs={12}>
                    <ButtonComponent
                        variant="text"
                        onClick={createClient}
                        size="medium"
                        startIcon={<Add />}
                    >
                        Creare client nou
                    </ButtonComponent>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <AsyncSelectComponent
                            placeholder="Cauta un client"
                            options={customersOptions}
                            renderOption={(props, option) => (
                                <Box component="li" {...props}>
                                    {[option.fullName, option.email, option.phoneNumber].filter(f => f).join(' - ')}
                                </Box>
                            )}
                            onOptionChange={onCustomerSelected}
                            getOptionLabel={option => [option.fullName, option.email, option.phoneNumber].filter(f => f).join(' - ')}
                            value={selectedCustomer ?? null}
                            isOptionEqualToValue={(option, selectedCustomer) => (option.id === selectedCustomer.id ? true : false)}
                        />
                    </Grid>
                </Grid>

                {
                    selectedCustomer?.id ?
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <div className="button-inline">
                                <ButtonComponent
                                    variant="text"
                                    onClick={ () => openClientEditDetails(selectedCustomer)}
                                    size="medium"
                                    startIcon={<Edit />}
                                >
                                    Editeaza client
                                </ButtonComponent>
                                <ButtonComponent
                                    variant="text"
                                    onClick={ () => openClientActionLog(selectedCustomer)}
                                    size="medium"
                                    startIcon={<History />}
                                >
                                    Vezi Istoric client
                                </ButtonComponent>
                            </div>
                        </Grid>
                    </Grid>
                    :
                    ''
                }
            </FormSectionComponent>

            {selectedCustomer?.id ?

                <FormSectionComponent variant="panel" title="Adrese">
                    <Grid item xs={6}>
                        <ButtonComponent
                            variant="text"
                            onClick={selectShippingAddress}
                            size="medium"
                            startIcon={<Add />}
                        >
                        {shippingAddress.fields.firstName.value ? 'Schimba adresa facturare' : 'Adauga adresa facturare'}
                        </ButtonComponent>
                    </Grid>

                    {shippingAddress.fields.firstName?.value ?
                        <>
                            <ListItemButton
                                selected={!selectedIndex}
                                onClick={(event) => handleShippingAddressClick()}
                            >
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <ListItemText
                                        primary={[shippingAddress.fields.firstName.value, shippingAddress.fields.lastName.value].filter(f => f).join(' ')}
                                    />
                                    <ListItemText
                                        disableTypography={true}
                                        sx={{
                                            color: themeVariables.colors.primary,
                                            fontWeight: themeVariables.fontWeights.bold,
                                            fontSize: themeVariables.fontSizes.subtitle
                                        }}
                                        secondary={[shippingAddress.fields.phoneNumber.value].filter(f => f).join(' ')}
                                    />
                                    <ListItemText
                                        secondary={[shippingAddress.fields.city.value, shippingAddress.fields.county.value, shippingAddress.fields.postalCode.value, shippingAddress.fields.street.value, shippingAddress.fields.streetNumber.value, shippingAddress.fields.flat.value, shippingAddress.fields.building.value, shippingAddress.fields.floor.value, shippingAddress.fields.apartmentNumber.value].filter(f => f).join(', ')}
                                    />
                                    <ListItemText
                                        secondary={[shippingAddress.fields.companyName.value, shippingAddress.fields.vatCode.value, shippingAddress.fields.regCode.value].filter(f => f).join(' ')}
                                    />
                                </div>
                            </ListItemButton>

                            {/* {oldShippingAddress && selectedCustomer.shippingAddress ? 
                                <>
                                    {oldShippingAddress?.length < selectedCustomer.shippingAddress?.length ?
                                    <Grid item xs={12}>
                                        <FormControlLabel 
                                            control={<Checkbox 
                                                checked={saveNewShippingAddress} 
                                                onChange={e => checkSaveNewShippingAddress(e.target.checked)}
                                            />} 
                                            label="Doriti sa salvati noua adresa adaugata?" 
                                        />
                                    </Grid> : ''
                                    }
                                </> 
                                : ''
                            }  */}
                        </>
                        : ''
                    }

                    <Grid item xs={12}>
                        <FormControlLabel
                            control={<Checkbox
                                checked={billingAddress.fields.useShippingAsBilling.value}
                                onChange={e => onSameAddressCheckboxChange(e.target.checked)}
                            />}
                            label="Aceeasi cu adresa de livrare"
                        />
                    </Grid>

                    {!billingAddress.fields.useShippingAsBilling.value ?
                        <Grid item xs={6}>
                            <ButtonComponent
                                variant="text"
                                onClick={selectBillingAddress}
                                size="medium"
                                startIcon={<Add />}
                            >
                                {billingAddress.fields.firstName.value ? 'Schimba adresa facturare' : 'Adauga adresa facturare'}
                            </ButtonComponent>
                        </Grid> : ''
                    }

                    {billingAddress.fields.firstName.value ?
                        <>
                            <ListItemButton
                                selected={!selectedBillingIndex}
                                // onClick={(event) => billingAddress.fields.useShippingAsBilling.value ? '' : handleBillingAddressClick()}
                                onClick={(event) => handleBillingAddressClick()}
                            >
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <ListItemText
                                        primary={[billingAddress.fields.firstName.value, billingAddress.fields.lastName.value].filter(f => f).join(' ')}
                                    />
                                    <ListItemText
                                        disableTypography={true}
                                        sx={{
                                            color: themeVariables.colors.primary,
                                            fontWeight: themeVariables.fontWeights.bold,
                                            fontSize: themeVariables.fontSizes.subtitle
                                        }}
                                        secondary={[billingAddress.fields.phoneNumber.value, billingAddress.fields.companyName.value].filter(f => f).join(' ')}
                                    />
                                    <ListItemText
                                        secondary={[billingAddress.fields.city.value, billingAddress.fields.county.value, billingAddress.fields.postalCode.value].filter(f => f).join(', ')}
                                    />
                                    <ListItemText
                                        secondary={[billingAddress.fields.otherDetails.value].filter(f => f).join(' ')}
                                    />
                                </div>
                            </ListItemButton>
                            {/* {oldBillingAddress && selectedCustomer.billingAddress && !billingAddress.fields.useShippingAsBilling.value ? 
                                <>
                                    {oldBillingAddress?.length < selectedCustomer.billingAddress?.length ?
                                    <Grid item xs={12}>
                                        <FormControlLabel 
                                            control={<Checkbox 
                                                checked={saveNewBillingAddress} 
                                                onChange={e => checkSaveNewBillingAddress(e.target.checked)}
                                            />} 
                                            label="Doriti sa salvati noua adresa adaugata?" 
                                        />
                                    </Grid> : ''
                                    }
                                </> 
                                : ''
                            } */}
                        </>
                        : ''

                    }

                </FormSectionComponent> : ''

            }

            <div className="button-container">
                <ButtonComponent onClick={orderAction} isLoading={isLoading}>
                    {orderDetail ? 'Modifica' : 'Creeaza'}
                </ButtonComponent>
            </div>
        </OrderPanelComponentStyled>
    )

}

export default OrderPanelComponent;

