import { LinearProgress } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { customerRangeDateReq } from '../../utils/requests';
import DonutPatternChart from '../donut-pattern-chart/donut-pattern-chart.component';
import VirtualizedList from '../list-raport/list-raport.component';
import { RangeDateTypeProps } from '../overall-stats/overall-stats.component';

export type ClientStatsComponentPropsType = {
    rangeDate: RangeDateTypeProps,
    tabIndex: number
}

export type ShowDataCardPropsType = {
    label: string,
    value: any
}

export type CustomerRangeDateReqPropsType = {
    nrCustomers: ShowDataCardPropsType,
    newClientsPerAgent: ShowDataCardPropsType,
    nrClientsPerCounty: ShowDataCardPropsType,
    nrIndividuals: ShowDataCardPropsType,
    nrLegalEntities: ShowDataCardPropsType,
    topCustomer: ShowDataCardPropsType
}
const ClientStatsComponent = ({
    rangeDate,
    tabIndex
}: ClientStatsComponentPropsType) => {

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [showDataCard, setDataCard] = useState<CustomerRangeDateReqPropsType>({
        nrCustomers: {
            label: 'Total Clienti',
            value: 0
        },
        newClientsPerAgent: {
            label: 'Clienti noi / agent',
            value: []
        },
        nrClientsPerCounty: {
            label: 'Client / Judet',
            value: []
        },
        nrIndividuals: {
            label: 'Total persoane fizice',
            value: 0
        },
        nrLegalEntities: {
            label: 'Total persoane juridice',
            value: 0
        },
        topCustomer: {
            label: 'Top Clienti',
            value: {}
        }
    })

    useEffect(
        () => {
            async function loadingState() {
                setIsLoading(true)
                if(tabIndex === 2){
                    const response = await customerRangeDateReq(rangeDate)
                    setDataCard( 
                            (fields) => ({
                                nrCustomers: {
                                    ...fields.nrCustomers,
                                    value: response?.nrCustomers ?? 0
                                },
                                newClientsPerAgent: {
                                    ...fields.newClientsPerAgent, 
                                    value: response?.newClientsPerAgent},
                                nrClientsPerCounty: {
                                    ...fields.nrClientsPerCounty,
                                    value: response?.nrClientsPerCounty
                                },
                                nrIndividuals: {
                                    ...fields.nrIndividuals,
                                    value: response?.nrIndividuals ?? 0
                                },
                                nrLegalEntities: {
                                    ...fields.nrLegalEntities,
                                    value: response?.nrLegalEntities ?? 0
                                },
                                topCustomer: {
                                    ...fields.topCustomer,
                                    value: response?.topCustomer 
                                }
                            }))
                        
                }
                setIsLoading(false)

            }

            loadingState()
        },
        [rangeDate, tabIndex]
    )

    const donutPatternChart = useMemo(
        () => {
            return {
                label: [showDataCard.nrIndividuals.label, showDataCard.nrLegalEntities.label],
                value: [showDataCard.nrIndividuals.value, showDataCard.nrLegalEntities.value]
            }
        },
        [showDataCard]
    )

    const styleList = useMemo(
        () => {
            return {
                width: 688,
                height: 407
            }
        },
        []
    )

    const styleBox = useMemo(
        () => {
            return {
                width: '43rem',
                height: '23.44rem'
            }
        },
        []
    )

    return (
        <>
            {
                isLoading ? 
                    <LinearProgress style={{borderRadius: '10px'}} />
                    :
                    <>
                        {
                            tabIndex === 2 ? 
                                <div style={{display: 'flex', justifyContent: 'space-between', flexWrap: "wrap"}}>
                                    <DonutPatternChart details={donutPatternChart} generic={{label: showDataCard.nrCustomers.label}} />
                                    <VirtualizedList details={showDataCard.topCustomer} styleList={styleList} styleBox={styleBox} currency={true} />
                                </div>
                                : 
                                <></>
                        }
                    </> 
            }
        </>
        
    )
}

export default ClientStatsComponent;