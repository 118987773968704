import React, { useCallback, useEffect,useMemo,useState } from "react";
import { AwbTypeEnum } from "../../enums/awb-type.enum";
import { HeaderTypeEnum } from "../../enums/header-type.enum";
import { TableTypeEnum } from "../../enums/table-type.enum";
import { useStore } from "../../hooks/store.hook";
import { GenerateAwbVm, LineItemType, OrderType } from "../../types/order.type";
import { generateAwb } from "../../utils/requests";
import ButtonComponent from "../button/button.component";
import TableComponent, { CustomRendererPropsType, SelectedStatePropsType, TableDataPropsType } from "../table/table.component";
import AssignAwbComponentStyled from "./assign-awb.component.styled";
import toast from 'react-hot-toast';
import FormSectionComponent from "../form-section/form-section.component";
import { StateFieldType } from "../../types/form.types";
import { Grid, InputAdornment } from "@mui/material";
import TextFieldComponent from "../text-field/text-field.component";
import { CurrencyEnum } from "../../enums/currency.enum";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from "moment";
import { DialogResponseTypeEnum } from "../../enums/dialog-response-type.enum";
import { isEmpty } from "lodash";

type StateType = {
    fields: {
        quantity: StateFieldType<string>;
        weight: StateFieldType<string>;
        content: StateFieldType<string>;
        payment: StateFieldType<string>;
        customerReference: StateFieldType<string>;
        pickupDate: StateFieldType<Date>;
    };
    shouldDisplayError: boolean;
}

type AssignAwbLineItemType = {
    id: string;
    items?: LineItemType[];
} & Omit<LineItemType, 'childrenItems'>

export type AssignAwbComponentPropsType = {
    orderDetails?: OrderType;
    assignAwbWithoutInvoiceReason?: string;
}

const AssignAwbComponent = ({
    orderDetails,
    assignAwbWithoutInvoiceReason
}: AssignAwbComponentPropsType) => {

    const uiStore = useStore('uiStore');

    const [selectedAwbType, setSelectedAwbType] = useState<AwbTypeEnum>(AwbTypeEnum.NemoExpress);
    const [selectedLineItems, setSelectedLineItems] = useState<AssignAwbLineItemType[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [state, setState] = useState<StateType>({
        fields: {
            content: {
                value: '',
                isValid: false,
                errorMessage: 'Furnizati un continut valid'
            },
            customerReference: {
                value: '',
                isValid: false,
                errorMessage: 'Furnizati o referinta valida'
            },
            quantity: {
                value: '0',
                isValid: false,
                errorMessage: 'Furnizati un numar de colete valid'
            },
            weight: {
                value: '0',
                isValid: false,
                errorMessage: 'Furnizati o greutate valida'
            },
            payment: {
                value: '0',
                isValid: true,
                noValidation: true
            },
            pickupDate: {
                value: moment(new Date()).add(1, 'days').toDate(),
                isValid: true,
                errorMessage: 'Furnizati o data de ridicare valida'
            }
        },
        shouldDisplayError: false
    });

    const parsedLineItems = useMemo(
        (): AssignAwbLineItemType[] => {
            return (orderDetails?.lineItems || [])
                .map((item: LineItemType) => ({
                    ...item,
                    id: item.sku,
                    items: item.childrenItems?.map(m => ({
                        ...m,
                        parentId: item.id
                    }))
                }))
        },
        [orderDetails?.lineItems]
    )

    useEffect(
        () => {
            setSelectedAwbType(() => {
                return orderDetails?.awbs?.findIndex(f => f.type === AwbTypeEnum.NemoExpress) === -1 ? 
                    AwbTypeEnum.NemoExpress : 
                    AwbTypeEnum.Dpd
            })
        },
        [orderDetails?.awbs]
    )

    useEffect(
        () => {
            uiStore.updatePanel({
                subtitleComponent: () => (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {
                            orderDetails?.awbs?.findIndex(f => f.type === AwbTypeEnum.NemoExpress) === -1 ?
                                <ButtonComponent
                                    variant="outlined"
                                    color={selectedAwbType === AwbTypeEnum.NemoExpress ? 'success' : 'secondary'}
                                    style={{marginRight: '0.5rem'}}
                                    onClick={() => setSelectedAwbType(() => AwbTypeEnum.NemoExpress)}
                                >
                                    Nemo express
                                </ButtonComponent> :
                                ''
                        }
                        
                        {
                            orderDetails?.awbs?.findIndex(f => f.type === AwbTypeEnum.Dpd) === -1 ?
                                <ButtonComponent
                                    variant="outlined"
                                    color={selectedAwbType === AwbTypeEnum.Dpd ? 'success' : 'secondary'}
                                    onClick={() => setSelectedAwbType(() => AwbTypeEnum.Dpd)}
                                >
                                    DPD
                                </ButtonComponent> :
                                ''
                        }
                        
                    </div>
                )
            })
        },
        [uiStore, selectedAwbType, orderDetails?.awbs]
    )

    const tableData = useMemo(
        (): TableDataPropsType => {
            const totalElements = parsedLineItems.reduce((prev, curr) => {
                return prev + (isEmpty(curr.items) ? 1 : (curr.items?.length ?? 0));
            }, 0);

            return {
                data: parsedLineItems,
                headers: [
                   {
                       id: 'name',
                       label: 'Nume',
                       headerType: HeaderTypeEnum.String,
                       alignment: 'left',
                       sortable: false
                   } 
                ],
                totalElements: totalElements,
                totalUnfilteredElements: totalElements
            }
        },
        [parsedLineItems]
    )

    const customRenderer = useMemo(
        (): CustomRendererPropsType => {
            return {
                name: (row: LineItemType) => {
                    return (
                        <>
                            <div className="product-sku">{row.sku}</div>
                            <div className="product-name">{row.title}</div>
                        </>
                    )
                },
            }
        },
        []
    )

    const onAssignAwb = useCallback(
        async () => {

            setState(() => ({
                ...state,
                shouldDisplayError: true
            }));

            const isNotValid = Object.values(state.fields).some(field => !field.isValid);
            if (isNotValid) {
                return;
            }

            if (!orderDetails?.payment?.isPaid) {
                const resp = await uiStore.openDialog({
                    title: 'Generare AWB',
                    message: 'Esti sigur ca suma de ramburs pentru produsele selectate este corecta?'
                });

                if (resp.value !== DialogResponseTypeEnum.Confirm) return;
            }

            const data: GenerateAwbVm = {
                content: state.fields.content.value,
                customerReference: state.fields.customerReference.value,
                quantity: +state.fields.quantity.value,
                weight: +state.fields.weight.value,
                pickupDate: state.fields.pickupDate.value,
                price: orderDetails?.payment?.isPaid ? undefined : +state.fields.payment.value,
                selectedItemsSkus: selectedLineItems.map(item => ({sku: item.sku, parentId: item.parentId})),
                assignAwbWithoutInvoiceReason: assignAwbWithoutInvoiceReason
            }

            setIsLoading(() => true);
            generateAwb(selectedAwbType, orderDetails?.id ?? '', data)
                .then((updatedOrder: OrderType) => {
                    uiStore.dismissPanel({
                        data: updatedOrder
                    });
                })
                .catch((e: any) => {
                    toast.error(e.message)
                })
                .finally(() => {
                    setIsLoading(() => false);
                })
        },
        [selectedAwbType, orderDetails, uiStore, state, selectedLineItems, assignAwbWithoutInvoiceReason]
    )

    const updateStateField = useCallback(
        (currentField: StateFieldType<any>, newValue: any): StateFieldType<any> => {
            return {
                ...currentField,
                isValid: (currentField.validator ? (currentField.validator as any)(newValue) : !!newValue) || 
                    currentField.noValidation,
                value: newValue
            }
        },
        []
    )

    const updateState = useCallback(
        <T extends keyof typeof state.fields>(field: T, newValue: any) => {
            setState(state => ({
                ...state,
                fields: {
                    ...state.fields,
                    [field]: updateStateField(state.fields[field], newValue)
                }
            }))
        },
        [state, updateStateField]
    )
    
    useEffect(
        () => {
            if (!orderDetails) return;

            let ref: string = orderDetails.orderId;
            if (orderDetails.invoice) {
                ref += ' - ' + orderDetails.invoice?.series + ' ' + orderDetails.invoice?.number;
            }

            setState(state => ({
                ...state,
                fields: {
                    ...state.fields,
                    customerReference: updateStateField(state.fields.customerReference, ref),
                    payment: orderDetails.payment?.isPaid ? {
                        ...state.fields.payment,
                        isValid: true,
                        noValidation: true
                    } : {
                        ...state.fields.payment,
                        isValid: false,
                        errorMessage: 'Furnizati o suma de ramburs valida',
                        validator: value => +value > 0
                    }
                }
            }))
        },
        [orderDetails, updateStateField]
    )

    useEffect(
        () => {
            if (orderDetails?.payment?.isPaid) return;

            const price: number = selectedLineItems.reduce((prev, curr) => {
                return prev + ((curr.unitPrice ?? 0) - (curr.discount ?? 0)) * curr.quantity
            }, 0);

            setState(state => ({
                ...state,
                fields: {
                    ...state.fields,
                    payment: updateStateField(state.fields.payment, price.toString())
                }
            }))
        },
        [selectedLineItems, orderDetails, updateStateField]
    )

    const onSelectRowsHandler = useCallback(
        (selectedData: SelectedStatePropsType) => {
            const flattenedItems = parsedLineItems.map(m => isEmpty(m.items) ? m : m.items as LineItemType[]).flat();
            const selectedItems: AssignAwbLineItemType[] = selectedData.isAllSelected ?
                flattenedItems.filter(f => !selectedData.selectedItems.some(s => s.id === f.id)) :
                selectedData.selectedItems
            setSelectedLineItems(() => selectedItems);
            
        },
        [parsedLineItems]
    )


    /** define the return statement bellow */
    return (
        <AssignAwbComponentStyled>
            <FormSectionComponent variant="panel" title="Selecteaza produsele">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TableComponent
                            tableKey={TableTypeEnum.AssignAwbTable}  
                            withoutSearchBar
                            withoutPagination
                            withoutDenseSwitch
                            viewType="panel"
                            tableData={tableData}
                            denseByDefault
                            customRenderer={customRenderer}
                            onSelectRows={onSelectRowsHandler}
                        />
                    </Grid>
                </Grid>
            </FormSectionComponent>

            <FormSectionComponent variant="panel" title="Informatii suplimentare">
                <Grid container spacing={2}>
                    <Grid item xs={orderDetails?.payment?.isPaid ? 6 : 4}>
                        <TextFieldComponent
                            label="Numar colete" 
                            variant="outlined" 
                            type="number"
                            fullWidth={true}
                            value={state.fields.quantity.value}
                            error={state.shouldDisplayError && !state.fields.quantity.isValid}
                            helperText={state.shouldDisplayError && !state.fields.quantity.isValid && state.fields.quantity.errorMessage}
                            onTextChange={e => updateState('quantity', e)}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">colete</InputAdornment>,
                            }}
                        />
                    </Grid>

                    <Grid item xs={orderDetails?.payment?.isPaid ? 6 : 4}>
                        <TextFieldComponent
                            label="Greutate" 
                            variant="outlined" 
                            type="number"
                            fullWidth={true}
                            value={state.fields.weight.value}
                            error={state.shouldDisplayError && !state.fields.weight.isValid}
                            helperText={state.shouldDisplayError && !state.fields.weight.isValid && state.fields.weight.errorMessage}
                            onTextChange={e => updateState('weight', e)}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                            }}
                        />
                    </Grid>

                    {
                        !orderDetails?.payment?.isPaid ?
                        <Grid item xs={4}>
                            <TextFieldComponent
                                label="Suma ramburs" 
                                variant="outlined" 
                                type="number"
                                fullWidth={true}
                                value={state.fields.payment.value}
                                error={state.shouldDisplayError && !state.fields.payment.isValid}
                                helperText={state.shouldDisplayError && !state.fields.payment.isValid && state.fields.payment.errorMessage}
                                onTextChange={e => updateState('payment', e)}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                        {CurrencyEnum.RON}
                                    </InputAdornment>,
                                }}
                            />
                        </Grid> :
                        ''                          
                    }

                    <Grid item xs={12}>
                        <TextFieldComponent
                            label="Continut" 
                            variant="outlined" 
                            multiline
                            rows={3}
                            fullWidth={true}
                            value={state.fields.content.value}
                            error={state.shouldDisplayError && !state.fields.content.isValid}
                            helperText={state.shouldDisplayError && !state.fields.content.isValid && state.fields.content.errorMessage}
                            onTextChange={e => updateState('content', e)}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextFieldComponent
                            label="Referinta client" 
                            variant="outlined" 
                            fullWidth={true}
                            value={state.fields.customerReference.value}
                            error={state.shouldDisplayError && !state.fields.customerReference.isValid}
                            helperText={state.shouldDisplayError && !state.fields.customerReference.isValid && state.fields.customerReference.errorMessage}
                            onTextChange={e => updateState('customerReference', e)}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <DatePicker
                            value={state.fields.pickupDate.value}
                            renderInput={(params) => <TextFieldComponent {...params} fullWidth={true} variant="outlined" label="Data ridicare" />}
                            onChange={newValue => updateState('pickupDate', newValue)}
                            mask="__.__.____"
                        />
                    </Grid>
                </Grid>
            </FormSectionComponent>

            <div className="button-container">
                <ButtonComponent onClick={onAssignAwb} isLoading={isLoading}>
                    Genereaza AWB
                </ButtonComponent>
            </div>
        </AssignAwbComponentStyled>
    )

}

export default AssignAwbComponent;