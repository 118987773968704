import { StorageEnum } from "../../../../enums/storage.enum";
import {  Navigate } from "react-router-dom";

const LogoutPage = () => {
    const token = localStorage.getItem(StorageEnum.Token);

    if (token) 
        localStorage.clear()
    return <><Navigate to="/auth/login" /></>
    
}

export default LogoutPage;