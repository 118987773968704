import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Divider, IconButton, LinearProgress, Tooltip } from "@mui/material";
import { cloneDeep, isEmpty } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { LogItemTypeEnum } from "../../enums/log-item-type.enum";
import { PanelType } from "../../enums/panel-type.enum";
import { useStore } from "../../hooks/store.hook";
import { LogItemType } from "../../types/log.type";
import { getCustomersLogs } from "../../utils/requests";
import AvatarComponent from "../avatar/avatar.component";
import ButtonComponent from "../button/button.component";
import CardNumericActionLogRaport from "../card-numeric-action-log/card-numeric-action-log.component";
import NoDataComponent from "../no-data/no-data.component";
import OrderDetailsHistoryTabComponentStyled from "../order-details-history-tab/order-details-history-tab.component.styled";
import OrderDetailsComponent from "../order-details/order-details.component";
import OrderHistoryHeaderComponent from "../order-history-header/order-history-header.component";

export type CustomerDetailsHistoryTabComponentPropsType = {
    customerId?: string
}

export type LogTypeCustomer = {
   items: {
        items: LogItemType[],
        orderId: string,
        createdAt: string,
        orderIdFromDb: string
        }[];
   startDate: Date,
   endDate: Date,
   totalLogsCount: number;
   customerRaport: {
        source: string,
        createdAt: Date,
        totalOrders: number,
        legalEntity: boolean,
        valOrdersCompleted: number,
        nrOrdersCompleted: number,
        nrOrdersCanceled: number
   };
}

const CustomerDetailsHistoryTabComponent = ({
    customerId
}: CustomerDetailsHistoryTabComponentPropsType) => {

    const uiStore = useStore('uiStore');
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [isExpanded, setIsExpanded] = React.useState<{ [index: string]: boolean }>({});
    const [history, setHistory] = useState<LogTypeCustomer>();
    
    const fetchHistory = useCallback(
        ({startDate, endDate}: { startDate?: Date, endDate?: Date }) => {
            setIsLoading(() => true)
            getCustomersLogs( customerId ?? '', startDate, endDate)
                .then((logs: LogTypeCustomer) => {
                    setHistory(() => logs);
                })
                .catch((e: any) => {
                    toast.error(e.message);
                })
                .finally(() => {
                    setIsLoading(() => false)
                })
        },
        [customerId]
    )

    const onDatesChange = useCallback(
        (newDates: { startDate?: Date, endDate?: Date }) => {
            fetchHistory(newDates);
        },
        [fetchHistory]
    )

    useEffect(
        () => {
            uiStore.setOrderData(true)
        },
        [uiStore]
    )

    useEffect(
        () => {
            if(!customerId) return
            fetchHistory({});
        },
        [customerId, fetchHistory]
    )

    useEffect(
        () => {
            uiStore.updatePanel({
                subtitleComponent: (!history || !history.totalLogsCount) ? undefined : () => (
                    <OrderHistoryHeaderComponent
                        minDate={history?.startDate ?? new Date()}
                        maxDate={history?.endDate ?? new Date()}
                        onDatesChangeCB={onDatesChange}
                    />
                )
            })
        },
        [history, onDatesChange, uiStore]
    )

    const constructMessage = useCallback(
        (logItem: LogItemType): React.ReactNode => {
            const tokens = logItem.message.match(/{{[a-z]*}}/g);
            const otherTextTokens = logItem.message.split(/{{[a-z]*}}/);

            const rawAllTokens: { data: string, index: number, type: 'token' | 'normal' }[] = [
                ...(tokens ?? []).map(token => ({
                   data: token,
                   index: logItem.message.indexOf(token),
                   type: 'token' as 'token' | 'normal'
                })),
                ...otherTextTokens.map(token => ({
                    data: token,
                    index: logItem.message.indexOf(token),
                    type: 'normal' as 'token' | 'normal'
                 }))
            ]
                .sort((a, b) => a.index - b.index);

            return (
                <>
                    {
                        rawAllTokens.map((token, index) => {
                            if (token.type === 'normal') {
                                return <span key={index}>{token.data}</span>
                            }
                            else {
                                const dataToken = logItem.data.find(f => f.key === token.data.substring(2, token.data.length-2));
                                if (!dataToken) return <span key={index}></span>
                                
                                switch(dataToken.type as LogItemTypeEnum) {
                                    case LogItemTypeEnum.Emphasis:
                                        return <span key={index} className="log-item-emphasis">{dataToken.data}</span>
                                    case LogItemTypeEnum.Verb:
                                    case LogItemTypeEnum.Normal:
                                        return <span key={index}>{dataToken.data}</span>
                                    case LogItemTypeEnum.User:
                                        return <span key={index} className="log-item-user">{ isEmpty(dataToken.data) ? '' : '@'+dataToken.data }</span>
                                    default:
                                        return <span key={index}></span>
                                }
                            }
                        })
                    }
                </>
            )
        },
        []
    )

    const getDatePart = useCallback(
        (date: string): string => {
            return date.split(' ').slice(0, 3).join(' ');
        },
        []
    )

    const getTimePart = useCallback(
        (date: string): string => {
            return date.split(' ')[date.split(' ').length-1];
        },
        []
    )

    const renderItem = useCallback(
        (item: LogItemType, index: number, items: LogItemType[]) => {
            return (
                <div className="log-item-wrapper" key={index}>
                    <div className="date-section">
                        <div>{getDatePart(item.createdAt)}</div>
                        <div>{getTimePart(item.createdAt)}</div>
                    </div>

                    <div className="icon-section">
                        <div className="line-section" style={{visibility: index > 0 ? 'visible' : 'hidden'}}></div>
                        <Tooltip title={item.user?.fullName ?? 'Fara utilizator'}>
                            <div className="avatar-section">
                                <AvatarComponent name={item.user?.fullName ?? ''} size="2.4rem" />
                            </div>
                        </Tooltip>
                        <div className="line-section" style={{visibility: index < items.length-1 ? 'visible' : 'hidden'}}></div>
                    </div>

                    <div className="content-section">
                        {
                            item.user ? 
                                <>
                                    <div className="content-user">
                                        @{item.user.displayName}
                                    </div>
                                    <Divider style={{marginBottom: '0.3rem'}} />
                                </> :
                                ''
                        }
                        <div className="content-message">
                            {constructMessage(item)}
                        </div>
                    </div>
                    
                </div>
            )
        },
        [constructMessage, getDatePart, getTimePart]
    )

    const onExpandButtonClick = React.useCallback(
        (index: number) => {
            const expandedCopy = cloneDeep(isExpanded);
            expandedCopy[index] = !expandedCopy[index];
            setIsExpanded(() => expandedCopy);
        },
        [isExpanded]
      )

    const openOrder = useCallback(
        (id: string) => {
            uiStore.openPanel({
                key: PanelType.ViewOrder,
                component: <OrderDetailsComponent orderId={id} />,
                title: 'Detalii comanda',
                panelWidth: '700px'
            });
        },
        [uiStore]
    )

    const raportCustomer = useMemo(
        () => {
            if(!history?.customerRaport) return
            return {
                    details: {
                        'Client creat la data de:': {
                            value: history?.customerRaport.createdAt,
                            price: false
                        },
                        'Sursa:': {
                            value: history?.customerRaport.source,
                            price: false
                        },
                        'Persoana juridica:': {
                            value: history?.customerRaport.legalEntity ? 'Da' : 'Nu',
                            price: false
                        },
                        'Numar comenzi finalizate/totale:': {
                            value: `${history?.customerRaport.nrOrdersCompleted}/${history?.customerRaport.totalOrders}`,
                            price: false
                        },
                        'Valoare comenzi finalizate:': {
                            value: history?.customerRaport.valOrdersCompleted,
                            price: true
                        },
                        'Numar comenzi anulate:': {
                            value: history?.customerRaport.nrOrdersCanceled,
                            price: false
                        },
                    }
                }
        },
        [history]
    )
    /** define the return statement bellow */
    return (
        <OrderDetailsHistoryTabComponentStyled>
            {
                (history && isEmpty(history?.items)) ?
                    <NoDataComponent message="Fara istoric de actiuni" /> 
                    :
                    isLoading ? 
                        <LinearProgress style={{ marginTop: '30px', borderRadius: '10px'}} />
                        : 
                        <>
                            <div style={{display: 'flex', flexWrap: 'wrap'}}>
                                {
                                    raportCustomer && raportCustomer.details ?
                                        Object.values(raportCustomer.details).map( (m, index) => (
                                            <CardNumericActionLogRaport label={Object.keys(raportCustomer.details)[index]} details={m} />
                                        ))
                                    : ''
                                }
                            </div>
                            {
                                history?.items.map( (m, index) => {
                                    if(m.orderId)
                                        return (
                                            <div style={{marginTop: '20px'}}>
                                                
                                                {
                                                    <>
                                                        <div className="log-item-wrapper" style={{flexDirection: 'column'}} key={index}>
                                                            <div style={{display: 'flex', width: '100%'}}>
                                                            <div style={{display: 'flex', alignItems: 'center'}}>
                                                                <IconButton
                                                                    aria-label="expand row"
                                                                    style={{ padding: '0', height: '50%' }}
                                                                    size="small"
                                                                    onClick={() => onExpandButtonClick(index)}
                                                                    >
                                                                    {isExpanded[index] ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                                                </IconButton>
                                                            </div>
                                                            <div style={{width: '80%'}}>
                                                                <ButtonComponent
                                                                    variant="text"
                                                                    onClick={() => openOrder(m.orderIdFromDb)}
                                                                    size="medium"
                                                                    sx={{textAlign: 'start'}}
                                                                >
                                                                    {`Comanda ${m.orderId}`}
                                                                </ButtonComponent>
                                                            </div>
                                                            <div className="date-section" style={{justifyContent: 'center', marginRight: '1rem', width: '100%', alignItems: 'flex-start'}}>
                                                                <div style={{display: 'flex'}}>
                                                                    <span>{`${getDatePart(m.createdAt)}, ${getTimePart(m.createdAt)}`}</span>
                                                                    <span></span>
                                                                </div>
                                                            </div>

                                                            </div>

                                                            <div 
                                                                className={`content-section`} 
                                                                style={{
                                                                    width: '100%', 
                                                                    display: 'flex', 
                                                                    justifyContent: 'flex-start', 
                                                                    flexDirection: 'column'
                                                            }}>
                                                                {
                                                                    isExpanded[index] ?
                                                                        m.items.length ?
                                                                            m.items.map(renderItem)
                                                                            :
                                                                            <NoDataComponent message="Fara istoric de actiuni" />
                                                                        :
                                                                        ''                                                 
                                                                }
                                                            </div>
                                                        </div>
                                                    </>
                                                } 
                                            </div>
                                        )
                                    else
                                        return m.items.map(renderItem)

                                })
                            }
                        </>
            }
        </OrderDetailsHistoryTabComponentStyled>
    )

}

export default CustomerDetailsHistoryTabComponent;