import { LinearProgress } from "@mui/material"
import { useEffect, useMemo, useState } from "react"
import { orderRangeDateReq } from "../../utils/requests"
import CardNumericRaport from "../card-numeric-raport/card-numeric-raport.component"
import { ShowDataCardPropsType } from "../client-stats/client-stats.component"
import VirtualizedList from "../list-raport/list-raport.component"
import { RangeDateTypeProps } from "../overall-stats/overall-stats.component"

export type OrderStatsComponentPropsType = {
    rangeDate: RangeDateTypeProps
    tabIndex: number
}

export type OrderRangeDateReqPropsType = {
    valOrders: ShowDataCardPropsType,
    nrOrders: ShowDataCardPropsType,
    nrInvoices: ShowDataCardPropsType,
    ordersInProcessByAgent: ShowDataCardPropsType,
    completedOrdersWithAwb: ShowDataCardPropsType,
    completedOrdersNoAwb: ShowDataCardPropsType,
    ordersPerCounty: ShowDataCardPropsType
}

const OrderStatsComponent = ({
    rangeDate,
    tabIndex
}: OrderStatsComponentPropsType) => {

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [showDataCard, setDataCard] = useState<OrderRangeDateReqPropsType>({
        valOrders: {
            label: 'Valoare totala comenzi',
            value: 0
        },
        nrOrders: {
            label: 'Numar total comenzi',
            value: 0
        },
        nrInvoices: {
            label: 'Numar total facturi',
            value: 0
        },
        ordersInProcessByAgent: {
            label: 'Total comenzi / agent',
            value: {}
        },
        completedOrdersWithAwb: {
            label: 'Total comenzi finalizate cu AWB si Factura',
            value: 0
        },
        completedOrdersNoAwb: {
            label: 'Total comenzi finalizate cu AWB si fara Factura',
            value: 0
        },
        ordersPerCounty: {
            label: 'Total comenzi / judet',
            value: {}
        }
    })

    useEffect(
        () => {
            async function loadingState() {
                setIsLoading(true)
                if(tabIndex === 3){
                    const response = await orderRangeDateReq(rangeDate)
                    setDataCard( 
                        (fields) => ({
                            valOrders: {
                                ...fields.valOrders,
                                value: response.valOrders
                            },
                            nrOrders: {
                                ...fields.nrOrders,
                                value: response.nrOrders
                            },
                            nrInvoices: {
                                ...fields.nrInvoices,
                                value: response.nrInvoices
                            },
                            ordersInProcessByAgent: {
                                ...fields.ordersInProcessByAgent,
                                value: response.ordersInProcessByAgent
                            },
                            completedOrdersWithAwb: {
                                ...fields.completedOrdersWithAwb,
                                value: response.completedOrdersWithAwb
                            },
                            completedOrdersNoAwb: {
                                ...fields.completedOrdersNoAwb,
                                value: response.completedOrdersNoAwb
                            },
                            ordersPerCounty: {
                                ...fields.ordersPerCounty,
                                value: response.ordersPerCounty
                            }
                        }))
                        
                }
                setIsLoading(false)
            }

            loadingState()
        },
        [rangeDate, tabIndex]
    )

    const styleList = useMemo(
        () => {
            return {
                width: 304,
                height: 270
            }
        },
        []

    )
    const styleBox = useMemo(
        () => {
            return {
                width: '19rem',
                height: '19.5rem'
            }
        },
        []
    )

    return (
        <div>
            {
                isLoading ? 
                    <LinearProgress style={{borderRadius: '10px'}} />
                    :
                    <>
                        {
                            tabIndex === 3 ?
                            <>    
                                <div style={{
                                    display: 'flex', 
                                    justifyContent: 'space-between', 
                                    flexWrap: 'wrap', 
                                }}>
                                    <CardNumericRaport details={showDataCard.valOrders} price={true}/>
                                    <CardNumericRaport details={showDataCard.nrOrders}  />
                                    <CardNumericRaport details={showDataCard.nrInvoices} />
                                </div>
                                
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'space-between'
                                }}>
                                    <div style={{display: 'flex', flexWrap: 'wrap'}}>
                                        <VirtualizedList details={showDataCard.ordersPerCounty} styleList={styleList} styleBox={styleBox} />
                                    </div>
                                    
                                    <div style={{
                                        display: 'flex', 
                                        justifyContent: 'space-between', 
                                        flexWrap: 'wrap', 
                                        flexDirection: 'column'
                                    }}>
                                        <CardNumericRaport details={showDataCard.completedOrdersWithAwb} />
                                        <CardNumericRaport details={showDataCard.completedOrdersNoAwb} />
                                    </div>

                                    <div style={{display: 'flex', flexWrap: 'wrap'}}>
                                        <VirtualizedList details={showDataCard.ordersInProcessByAgent} styleList={styleList} styleBox={styleBox} />
                                    </div>
                                    
                                </div> 
                            </>
                            :
                            <>
                            </>
                        }
                    </>
            }
        </div>
    )
}

export default OrderStatsComponent;