import React from 'react';
import AuthModuleStyled from './auth.module.styled';
import imageSrc from '../../assets/images/auth-background.jpg';
import AuthRouter from './auth.router';

const AuthModule = () => {

    /** define the return statement */
    return (
        <AuthModuleStyled>
            <div className='inner-content-wrapper'>
                <div className='section content-section'>
                    <AuthRouter />
                </div>
                <div className='section image-section'>
                    <div className='image-section-inner'>
                        <img src={imageSrc} alt="empty" />
                    </div>
                </div> 
            </div> 
        </AuthModuleStyled>
    )

}

export default AuthModule;