import { Checkbox, FormControlLabel, Grid, IconButton, InputAdornment } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { TableTypeEnum } from "../../enums/table-type.enum";
import { useStore } from "../../hooks/store.hook";
import { CreateCustomerType, CustomerType, UpdateCustomerType } from "../../types/customer.type";
import { StateFieldType } from "../../types/form.types";
import { createCustomer, getAnaf, getCustomer, updateCustomer } from "../../utils/requests";
import ButtonComponent from "../button/button.component";
import FormSectionComponent from "../form-section/form-section.component";
import TextFieldComponent from "../text-field/text-field.component";
import CustomerPanelComponentStyled from "./customer-panel.component.styled";
import SearchIcon from '@mui/icons-material/Search';
import { PanelType } from "../../enums/panel-type.enum";
import { Add } from "@mui/icons-material";
import SelectedListAddress from "../list-component/list-component.component";
import { isEmpty } from "lodash";
import { AddressTypeEnum } from "../../enums/address-type.enum";
import Address from "../address/address.component";
import { BillingAddressType, ShippingAddressType } from "../../types/address.type";
import { getBillingOtherDetailsFromShipping } from "../../utils/utils";


export type StateType = {
    fields: {
        email: StateFieldType<string>;
        firstName: StateFieldType<string>;
        lastName: StateFieldType<string>;
        shopifyId: StateFieldType<string>;
        phoneNumber: StateFieldType<string>;
        companyName: StateFieldType<string>;
        companyAddress: StateFieldType<string>;
        companyVatCode: StateFieldType<string>;
        companyRegCode: StateFieldType<string>;
        shippingAddress: StateFieldType<ShippingAddressType[]>;
        billingAddress: StateFieldType<BillingAddressType[]>;
        useShippingAsBilling: StateFieldType<boolean>;

    };
    shouldDisplayError: boolean;
}

export type CustomerPanelComponentPropsType = {
    customerId?: string,
    customerObj?: CustomerType,
    title?: string,
    customerPhone?: string,
}

const CustomerPanelComponent = ({
    customerId,
    customerObj,
    title,
    customerPhone
}: CustomerPanelComponentPropsType) => {

    /** inject mobx store inside  */
    const uiStore = useStore('uiStore');
    const tableStore = useStore('tableStore');

    const [state, setState] = useState<StateType>({
        fields: {
            email: {
                value: '',
                isValid: true
            },
            firstName: {
                value: '',
                isValid: true
            },
            lastName: {
                value: '',
                isValid: true
            },
            phoneNumber: {
                value: '',
                isValid: true
            },
            companyName: {
                value: '',
                isValid: true
            },
            companyAddress: {
                value: '',
                isValid: true
            },
            companyRegCode: {
                value: '',
                isValid: true
            },
            companyVatCode: {
                value: '',
                isValid: true
            },
            shopifyId: {
                value: '',
                isValid: true,
            },
            shippingAddress: {
                value: [],
                isValid: true,
            },
            billingAddress: {
                value: [],
                isValid: true,
            },
            useShippingAsBilling: {
                value: false,
                isValid: true,
            },

        },
        shouldDisplayError: false
    });

    const [shippingAddress, setShippingAddress] = useState<ShippingAddressType>()
    const [billingAddress, setBillingAddress] = useState<BillingAddressType>()
    const [isLoading, setIsLoading] = useState(false);
    const [contorFirmData, setContorFirmData] = useState(false)

    useEffect(
        () => {
            uiStore.updatePanel({title: title})
            if (customerId) {
                getCustomer(customerId)
                    .then(customer => {
                        setState(state => ({
                            ...state,
                            fields: {
                                ...state.fields,
                                email: { ...state.fields.email, value: customer.email ?? '' },
                                firstName: { ...state.fields.firstName, value: customer.firstName ?? '' },
                                lastName: { ...state.fields.lastName, value: customer.lastName ?? '' },
                                phoneNumber: { ...state.fields.phoneNumber, value: customer.phoneNumber ?? '' },
                                companyName: { ...state.fields.companyName, value: customer.company?.name ?? '' },
                                companyAddress: { ...state.fields.companyAddress, value: customer.company?.address ?? '' },
                                companyVatCode: { ...state.fields.companyVatCode, value: customer.company?.vatCode ?? '' },
                                companyRegCode: { ...state.fields.companyRegCode, value: customer.company?.regCode ?? '' },
                                shopifyId: { ...state.fields.shopifyId, value: customer.shopifyId ?? '' },
                                shippingAddress: { ...state.fields.shippingAddress, value: customer.shippingAddress ?? [] },
                                billingAddress: { ...state.fields.billingAddress, value: customer.billingAddress ?? [] },
                                useShippingAsBilling: { ...state.fields.useShippingAsBilling, value: customer.useShippingAsBilling ?? false },
                            }
                        }))
                    })
                    .catch(e => toast.error(e.message));
            } 

            if(customerObj){
                setState(state => ({
                    ...state,
                    fields: {
                        ...state.fields,
                        email: { ...state.fields.email, value: customerObj.email  ?? '' },
                        firstName: { ...state.fields.firstName, value: customerObj.firstName ?? '' },
                        lastName: { ...state.fields.lastName, value: customerObj.lastName ?? '' },
                        phoneNumber: { ...state.fields.phoneNumber, value: customerObj.phoneNumber ?? '' },
                        companyName: { ...state.fields.companyName, value: customerObj.company?.name ?? '' },
                        companyAddress: { ...state.fields.companyAddress, value: customerObj.company?.address ?? '' },
                        companyVatCode: { ...state.fields.companyVatCode, value: customerObj.company?.vatCode ?? '' },
                        companyRegCode: { ...state.fields.companyRegCode, value: customerObj.company?.regCode ?? '' },
                        shopifyId: { ...state.fields.shopifyId, value: customerObj.shopifyId ?? '' },
                        shippingAddress: { ...state.fields.shippingAddress, value: customerObj.shippingAddress ?? [] },
                        billingAddress: { ...state.fields.billingAddress, value: customerObj.billingAddress ?? [] },
                        useShippingAsBilling: { ...state.fields.useShippingAsBilling, value: customerObj.useShippingAsBilling ?? false },
                    }
                }))
            }
        },
        [customerObj, customerId, uiStore, title]
    )

    useEffect(
        () => {
                if(state.fields.billingAddress.value.length
                    ||state.fields.companyAddress.value
                    || state.fields.companyName.value
                    || state.fields.companyRegCode.value
                    || state.fields.companyVatCode.value
                    || state.fields.email.value
                    || state.fields.firstName.value
                    || state.fields.lastName.value
                    || state.fields.phoneNumber.value
                    || state.fields.shippingAddress.value.length
                    )
                        uiStore.setOrderData(true)
                else
                    uiStore.setOrderData(false)
        },
        [
            state.fields.billingAddress.value.length, 
            state.fields.companyAddress.value, 
            state.fields.companyName.value, 
            state.fields.companyRegCode.value,
            state.fields.companyVatCode.value, 
            state.fields.email.value, 
            state.fields.firstName.value, 
            state.fields.lastName.value, 
            state.fields.phoneNumber.value, 
            state.fields.shippingAddress.value.length, 
            uiStore
        ]
    )

    useEffect(
        () => {
            uiStore.updatePanel({
                subtitleComponent:  undefined 
            })
        },
        [uiStore]
    )

    useEffect(
        () => {
            uiStore.updatePanel({title: title})
            if (customerPhone) {
                setState(state => ({
                    ...state,
                    fields: {
                        ...state.fields,
                        phoneNumber: { ...state.fields.phoneNumber, value: customerPhone ?? '' },
                    }
                }))

            } 
        },
        [customerPhone, uiStore, title]
    )

    const isEdit = useMemo(
        (): boolean => {
            return !!customerId;
        },
        [customerId]
    )

    const updateState = useCallback(
        <T extends keyof typeof state.fields>(field: T, newValue: any) => {
            setState(state => ({
                ...state,
                fields: {
                    ...state.fields,
                    [field]: {
                        ...state.fields[field],
                        isValid: true,
                        value: newValue
                    }
                }
            }))
        },
        [state]
    )

    const customerAction = useCallback(
        async () => {
            const isNotValid = Object.values(state.fields).some(field => !field.isValid);
            if (isNotValid) return;

            state.fields.shippingAddress.value.length = 0;
            state.fields.billingAddress.value.length = 0;

            if (shippingAddress)
                state.fields.shippingAddress.value.push(shippingAddress);

            if (billingAddress)
                state.fields.billingAddress.value.push(billingAddress);

            var billAddress: BillingAddressType;
            if (state.fields.useShippingAsBilling.value === true) {
                billAddress = {
                    firstName: shippingAddress?.firstName,
                    lastName: shippingAddress?.lastName,
                    phoneNumber: shippingAddress?.phoneNumber,
                    companyName: shippingAddress?.companyName,
                    city: shippingAddress?.city,
                    county: shippingAddress?.county,
                    vatCode: shippingAddress?.vatCode,
                    regCode: shippingAddress?.regCode,
                    otherDetails: getBillingOtherDetailsFromShipping(shippingAddress)
                }

                state.fields.billingAddress.value.push(billAddress)
            }

            setState(state => ({
                ...state,
                shippingAddress: { ...state.fields.shippingAddress, value: state.fields.shippingAddress.value },
                billingAddress: { ...state.fields.billingAddress, value: state.fields.billingAddress.value },
                shouldDisplayError: true
            }));

            const data: CreateCustomerType | UpdateCustomerType = {
                id: customerId,
                email: state.fields.email.value,
                phoneNumber: state.fields.phoneNumber.value,
                firstName: state.fields.firstName.value,
                lastName: state.fields.lastName.value,
                shopifyId: state.fields.shopifyId.value,
                company: {
                    name: state.fields.companyName.value,
                    address: state.fields.companyAddress.value,
                    vatCode: state.fields.companyVatCode.value,
                    regCode: state.fields.companyRegCode.value,
                },
                shippingAddress: state.fields.shippingAddress.value.map(m => ({
                    firstName: m.firstName,
                    lastName: m.lastName,
                    phoneNumber: m.phoneNumber,
                    companyName: m.companyName,
                    street: m.street,
                    streetNumber: m.streetNumber,
                    flat: m.flat,
                    building: m.building,
                    floor: m.floor,
                    apartmentNumber: m.apartmentNumber,
                    city: m.city,
                    county: m.county,
                    postalCode: m.postalCode,
                    vatCode: m.vatCode,
                    regCode: m.regCode,
                })),
                billingAddress: state.fields.billingAddress.value.map(m => ({
                    firstName: m.firstName,
                    lastName: m.lastName,
                    phoneNumber: m.phoneNumber,
                    companyName: m.companyName,
                    city: m.city,
                    county: m.county,
                    vatCode: m.vatCode,
                    regCode: m.regCode,
                    otherDetails: m.otherDetails,
                })),
            }
            
            setIsLoading(() => true);
            try {
                if (customerId) {
                    /** edit the customer */
                    await updateCustomer(customerId, data);
                } else {
                    /** create the customer */
                    await createCustomer(data);
                }
                toast.success('Clientul a fost salvat cu succes');
                uiStore.dismissPanel();
                tableStore.updateTable(TableTypeEnum.Customers)
            } catch (e: any) {
                toast.error(e.message);
            }
            setIsLoading(() => false);

        },
        [customerId, state, billingAddress, shippingAddress, tableStore, uiStore]
    )

    const onSameAddressCheckboxChange = useCallback(
        (isSelected: boolean) => {
            if (isSelected) {
                setState(state => ({
                    ...state,
                    fields: {
                        ...state.fields,
                        useShippingAsBilling: { ...state.fields.useShippingAsBilling, value: true },
                    }
                }))
            } else {
                setState(state => ({
                    ...state,
                    fields: {
                        ...state.fields,
                        useShippingAsBilling: { ...state.fields.useShippingAsBilling, value: false },
                    }
                }))
            }
        },
        []
    )

    const SearchFirm =
        async () => {
            try {
                const results = await getAnaf(state.fields.companyVatCode.value)
                setState(state => ({
                    ...state,
                    fields: {
                        ...state.fields,
                        companyName: { ...state.fields.companyName, value: results.data.found[0].denumire ?? '' },
                        companyAddress: { ...state.fields.companyAddress, value: results.data.found[0].adresa ?? '' },
                        companyRegCode: { ...state.fields.companyRegCode, value: results.data.found[0].nrRegCom ?? '' }
                    }
                }))
                if (!results.data.found[0].denumire)
                    setContorFirmData(true)
                else
                    setContorFirmData(false)

            } catch (e: any) {
                toast.error(e.message);
            }
        }


    const addShippingAddress = useCallback(
        () => {
            uiStore.openPanel({
                key: PanelType.AddAddress,
                title: "Adauga Adresa",
                component: <Address type={AddressTypeEnum.Shipping} editShippingAddressInfo={shippingAddress} />,
                onDismiss: (data: ShippingAddressType) => {
                    if (!data) return;
                    
                    setShippingAddress(() => ({
                        ...data
                    }))
                }
            })
        },
        [uiStore, shippingAddress]
    )

    const addBillingAddress = useCallback(
        () => {
            uiStore.openPanel({
                key: PanelType.AddAddress,
                title: "Adauga Adresa",
                component: <Address type={AddressTypeEnum.Billing} editBillingAddressInfo={billingAddress} />,
                onDismiss: (data: BillingAddressType) => {
                    if (!data) return;
                    setBillingAddress(() => ({
                        ...data
                    }))
                }
            })
        },
        [uiStore, billingAddress]
    )
    const selectShippingAddress = useCallback(
        () => {

            uiStore.openPanel({
                key: PanelType.EditAddress,
                title: "Selecteaza adresa livrare",
                component: <SelectedListAddress isShipping={true} shippingAddresses={state.fields.shippingAddress.value} customerId={customerId} />,
                onDismiss: (data: ShippingAddressType[]) => {
                    if (!data) return;

                    setState(state => ({
                        ...state,
                        fields: {
                            ...state.fields,
                            shippingAddress: { ...state.fields.shippingAddress, value: data },
                        }
                    }));
                }
            })
        },
        [uiStore, state.fields.shippingAddress.value, customerId]
    )
    
    const selectBillingAddress = useCallback(
        () => {
            uiStore.openPanel({
                key: PanelType.EditAddress,
                title: "Selecteaza adresa facturare",
                component: <SelectedListAddress isShipping={false} billingAddresses={state.fields.billingAddress.value} customerId={customerId} />,
                onDismiss: (data: BillingAddressType[]) => {
                    if (!data) return;

                    setState(state => ({
                        ...state,
                        fields: {
                            ...state.fields,
                            billingAddress: { ...state.fields.billingAddress, value: data },
                        }
                    }));
                }
            })
        },
        [uiStore, state.fields.billingAddress.value, customerId]
    )

    /** define the return statement bellow */
    return (
        <CustomerPanelComponentStyled>

            <FormSectionComponent variant="panel" title="Detalii generale" >
                <Grid container spacing={2}>

                    <Grid item xs={12}>
                        <TextFieldComponent
                            label="Adresa de email"
                            variant="outlined"
                            fullWidth={true}
                            value={state.fields.email.value}
                            error={state.shouldDisplayError && !state.fields.email.isValid}
                            helperText={state.shouldDisplayError && !state.fields.email.isValid && state.fields.email.errorMessage}
                            onTextChange={e => updateState('email', e)}
                            disabled={ customerObj ? true : false}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextFieldComponent
                            label="Numar de telefon"
                            variant="outlined"
                            fullWidth={true}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">+4</InputAdornment>,
                            }}
                            value={state.fields.phoneNumber.value}
                            error={state.shouldDisplayError && !state.fields.phoneNumber.isValid}
                            helperText={state.shouldDisplayError && !state.fields.phoneNumber.isValid && state.fields.phoneNumber.errorMessage}
                            onTextChange={e => updateState('phoneNumber', e)}
                            disabled={ customerObj ? true : false}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextFieldComponent
                            label="Prenume"
                            variant="outlined"
                            fullWidth={true}
                            value={state.fields.firstName.value}
                            error={state.shouldDisplayError && !state.fields.firstName.isValid}
                            helperText={state.shouldDisplayError && !state.fields.firstName.isValid && state.fields.firstName.errorMessage}
                            onTextChange={e => updateState('firstName', e)}
                            disabled={ customerObj ? true : false}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextFieldComponent
                            label="Nume"
                            variant="outlined"
                            fullWidth={true}
                            value={state.fields.lastName.value}
                            error={state.shouldDisplayError && !state.fields.lastName.isValid}
                            helperText={state.shouldDisplayError && !state.fields.lastName.isValid && state.fields.lastName.errorMessage}
                            onTextChange={e => updateState('lastName', e)}
                            disabled={ customerObj ? true : false}
                        />
                    </Grid>

                </Grid>

            </FormSectionComponent>

            <FormSectionComponent variant="panel" title="Detalii companie">

                <Grid container spacing={2}>

                    <Grid item xs={12} sx={{ position: "relative", display: "flex", alignItems: "center" }}>
                        <TextFieldComponent
                            label="Introdu Cod fiscal"
                            variant="outlined"
                            fullWidth={true}
                            InputProps={ 
                                customerObj ? 
                                    undefined
                                    :
                                    {
                                        endAdornment: <InputAdornment position="end">
                                            <IconButton onClick={SearchFirm} type="submit" aria-label="search">
                                                <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>,
                                    }
                            }
                            value={state.fields.companyVatCode.value}
                            error={state.shouldDisplayError && !state.fields.companyVatCode.isValid}
                            helperText={(state.shouldDisplayError && !state.fields.companyVatCode.isValid && state.fields.companyVatCode.errorMessage) || (contorFirmData && "Nu s-au gasit date, verifica CUI-ul.")}
                            onTextChange={e => updateState('companyVatCode', e)}
                            disabled={ customerObj ? true : false}
                        />

                    </Grid>

                    <Grid item xs={12}>
                        <TextFieldComponent
                            label="Denumire"
                            variant="outlined"
                            fullWidth={true}
                            value={state.fields.companyName.value}
                            error={state.shouldDisplayError && !state.fields.companyName.isValid}
                            helperText={state.shouldDisplayError && !state.fields.companyName.isValid && state.fields.companyName.errorMessage}
                            onTextChange={e => updateState('companyName', e)}
                            disabled={ customerObj ? true : false}
                        />
                    </Grid>


                    <Grid item xs={12}>
                        <TextFieldComponent
                            label="Adresa"
                            variant="outlined"
                            fullWidth={true}
                            value={state.fields.companyAddress.value}
                            error={state.shouldDisplayError && !state.fields.companyAddress.isValid}
                            helperText={state.shouldDisplayError && !state.fields.companyAddress.isValid && state.fields.companyAddress.errorMessage}
                            onTextChange={e => updateState('companyAddress', e)}
                            disabled={ customerObj ? true : false}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextFieldComponent
                            label="Numar Registrul Comertului"
                            variant="outlined"
                            fullWidth={true}
                            value={state.fields.companyRegCode.value}
                            error={state.shouldDisplayError && !state.fields.companyRegCode.isValid}
                            helperText={state.shouldDisplayError && !state.fields.companyRegCode.isValid && state.fields.companyRegCode.errorMessage}
                            onTextChange={e => updateState('companyRegCode', e)}
                            disabled={ customerObj ? true : false}
                        />
                    </Grid>

                </Grid>

            </FormSectionComponent>
            
            {
                !customerObj ? 
                    <FormSectionComponent variant="panel" title="Adrese">
                        {!isEmpty(customerId) ?
                            <>
                                <Grid item xs={6}>
                                    <ButtonComponent
                                        variant="text"
                                        onClick={selectShippingAddress}
                                        size="medium"
                                        startIcon={<Add />}
                                    >
                                        Vezi adresele de livrare
                                    </ButtonComponent>
                                </Grid>
                                <Grid item xs={6}>
                                    <ButtonComponent
                                        variant="text"
                                        onClick={selectBillingAddress}
                                        size="medium"
                                        startIcon={<Add />}
                                    >
                                        Vezi adresele de facturare
                                    </ButtonComponent>
                                </Grid>
                            </>
                            :
                            <>
                                <Grid item xs={6}>
                                    <ButtonComponent
                                        variant="text"
                                        onClick={addShippingAddress}
                                        size="medium"
                                        startIcon={<Add />}
                                    >
                                        {shippingAddress ? "Modifica adresa livrare" : "Adauga adresa livrare"}
                                    </ButtonComponent>
                                </Grid>
                                
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={<Checkbox
                                            checked={state.fields.useShippingAsBilling.value}
                                            onChange={e => onSameAddressCheckboxChange(e.target.checked)}
                                        />}
                                        label="Aceeasi cu adresa de livrare"
                                    />
                                </Grid>

                                {!state.fields.useShippingAsBilling.value ?
                                    <Grid item xs={6}>
                                        <ButtonComponent
                                            variant="text"
                                            onClick={addBillingAddress}
                                            size="medium"
                                            startIcon={<Add />}
                                        >
                                            {billingAddress ? "Modifica adresa facturare" : "Adauga adresa facturare"}
                                        </ButtonComponent>
                                    </Grid> : ''
                                }

                            </>
                        }

                    </FormSectionComponent>
                    :
                    <></>

            }

            {
                !customerObj ?
                    <div className="button-container">
                        <ButtonComponent onClick={customerAction} isLoading={isLoading}>
                            {isEdit ? 'Salveaza' : 'Creeaza'}
                        </ButtonComponent>
                    </div>
                    :
                    <></>

            }
            
        </CustomerPanelComponentStyled>
    )

}

export default CustomerPanelComponent;