import styled from "styled-components";

const CompanySettingsComponentStyled = styled.div`

    .button-container {
        margin-top: 1rem;
        display: flex;
        justify-content: flex-end;
        width: 100%;
    }


`;

export default CompanySettingsComponentStyled;