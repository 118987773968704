import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { getCustomer } from "../../utils/requests";
import { useStore } from "../../hooks/store.hook";
import { History, ListAlt} from "@mui/icons-material";
import { CustomerType } from "../../types/customer.type";
import CustomerDetailsHistoryTabComponent from "../customer-details-history-tab/customer-details-history-tab.component";
import OrderDetailsComponentStyled from "../order-details/order-details.component.styled";
import CustomerPanelComponent from "../customer-panel/customer-panel.component";

export enum CustomerDetailsTab {
    CustomerDetails = 0,
    ActionLog = 1
}

export type CustomerDetailsComponentPropsType = {
    customerId: string;
    tabFocused?: CustomerDetailsTab;
    title?: string,
}

const CustomerDetailsComponent = ({
    customerId,
    tabFocused,
    title
}:CustomerDetailsComponentPropsType) => {

    const uiStore = useStore('uiStore');
    const [customer, setCustomer] = useState<CustomerType | null>(null)
    const [tabIndex, setTabIndex] = useState<number>(0);

    useEffect(
        () => {
            
            uiStore.updatePanel({
                tabs: [
                    {
                        label: 'Detalii client',
                        icon: <ListAlt />
                    },
                    {
                        label: 'Istoric client',
                        icon: <History />,
                    }
                ],
                tabIndex: tabIndex,
                onTabChange: (newValue) => setTabIndex(() => newValue),
                title: title
            });
        },
        [ 
            tabIndex, 
            uiStore, 
            title
        ]
    )

    useEffect(
        () => {
            getCustomer(customerId)
                .then((customer: CustomerType) => {
                    setCustomer(() => customer);
                })
                .catch((e: any) => {
                    toast.error(e.message);
                })
        },
        [customerId]
    )

    useEffect(
        () => {
            if(!tabFocused) return
            setTabIndex( () => tabFocused)
        },
        [tabFocused]
    )

    /** define the return logic bellow */

    return (
        <OrderDetailsComponentStyled>

            {
                tabIndex === CustomerDetailsTab.CustomerDetails ?
                    <CustomerPanelComponent customerId={customer?.id} title={title} />
                    :
                    <CustomerDetailsHistoryTabComponent
                        customerId={customer?.id}
                    />
            }

        </OrderDetailsComponentStyled>
    )

}

export default CustomerDetailsComponent;