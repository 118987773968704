import * as React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { themeVariables } from '../../theme/variables';
import ButtonComponent from '../button/button.component';
import { PanelType } from '../../enums/panel-type.enum';
import { useStore } from '../../hooks/store.hook';
import { deleteBillingAddress } from '../../utils/requests';
import { AddressTypeEnum } from '../../enums/address-type.enum';
import Address from '../address/address.component';
import { ListComponentStyled } from './list-component.component.styled';
import { ShippingAddressType, BillingAddressType } from '../../types/address.type';
import { updateBillingAddress, updateShippingAddress, deleteShippingAddress } from '../../utils/requests';
import { DialogResponseTypeEnum } from '../../enums/dialog-response-type.enum';
import toast from 'react-hot-toast';
import { Grid, IconButton } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { CustomerType } from '../../types/customer.type';

type SelectedListAddressDto = {
    shippingAddresses?: ShippingAddressType[];
    billingAddresses?: BillingAddressType[];
    order?: boolean;
    selectIndex?: number;
    customerId?: string;
    isShipping: boolean;
    customer?: CustomerType | null;
}

export default function SelectedListAddress({
    shippingAddresses,
    billingAddresses,
    order,
    selectIndex,
    customerId,
    isShipping,
    customer
}: SelectedListAddressDto) {
    const [selectedIndex, setSelectedIndex] = React.useState(selectIndex);
    const [shippingAddress, setShippingAddress] = React.useState<ShippingAddressType[]>(shippingAddresses ?? [])
    const [billingAddress, setBillingAddress] = React.useState<BillingAddressType[]>(billingAddresses ?? [])
    const uiStore = useStore('uiStore');

    const handleListItemClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        index: number,
    ) => {
        setSelectedIndex(index);

        if (shippingAddress && shippingAddress.length > 0) {
            uiStore.openPanel({
                key: PanelType.EditAddress,
                title: "Editeaza adresa de livrare",
                component: <Address type={AddressTypeEnum.Shipping} editShippingAddressInfo={shippingAddress[index]} />,
                onDismiss: async (data: ShippingAddressType) => {
                    if (!data) return;

                    let newShippingAddress = [...shippingAddress];
                    let addressIndex = newShippingAddress.findIndex(o => o.id && o.id === data.id);
                    if (addressIndex > -1)
                        newShippingAddress[addressIndex] = data;
                    else
                        newShippingAddress.push(data)

                    if (!order) {
                        if (customerId)
                            await updateShippingAddress(customerId, data);
                    } else {
                        uiStore.dismissPanel({
                            data: {
                                shippingAddresses: newShippingAddress,
                                selectedIndex: index
                            }
                        });
                    }

                    setShippingAddress(() => newShippingAddress)
                }
            })
            //handle edit address
        } else if (billingAddress && billingAddress.length > 0) {
            uiStore.openPanel({
                key: PanelType.EditAddress,
                title: "Editeaza adresa de facturare",
                component: <Address type={AddressTypeEnum.Billing} editBillingAddressInfo={billingAddress[index]} />,
                onDismiss: async (data: BillingAddressType) => {
                    if (!data) return;

                    let newBillingAddress = [...billingAddress];
                    let addressIndex = newBillingAddress.findIndex(o => o.id && o.id === data.id);

                    if (addressIndex > -1)
                        newBillingAddress[addressIndex] = data;
                    else
                        newBillingAddress.push(data);

                    if (!order) {
                        if (customerId)
                            await updateBillingAddress(customerId, data);
                    } else {
                        uiStore.dismissPanel({
                            data: {
                                billingAddresses: newBillingAddress,
                                selectedIndex: index
                            }
                        });
                    }

                    setBillingAddress(() => newBillingAddress);
                }
            })
        }
    };

    const addShippingAddress = React.useCallback(
        async () => {
            uiStore.openPanel({
                key: PanelType.AddAddress,
                title: "Adauga adresa de livrare",
                component: <Address customer={customer} type={AddressTypeEnum.Shipping} />,
                onDismiss: async (data: ShippingAddressType) => {
                    if (!data || !shippingAddress) return;
                
                    const newShippingAddress = [...shippingAddress];

                    if (!order) {
                        if (customerId) {
                            data.id = await updateShippingAddress(customerId, data);
                            newShippingAddress.push(data);
                        }
                    }
                    else {
                        newShippingAddress.push(data);
                        uiStore.dismissPanel({
                            data: {
                                shippingAddresses: newShippingAddress,
                                selectedIndex: newShippingAddress.length - 1
                            }
                        });
                    }

                    setShippingAddress(() => newShippingAddress)
                }
            })
        },
        [
            shippingAddress, 
            customerId, 
            order, 
            uiStore,
            customer
        ]
    )

    const addBillingAddress = React.useCallback(
        () => {
            uiStore.openPanel({
                key: PanelType.AddAddress,
                title: "Adauga adresa de facturare",
                component: <Address customer={customer} type={AddressTypeEnum.Billing} />,
                onDismiss: async (data: BillingAddressType) => {
                    if (!data || !billingAddress) return;

                    const newBillingAddress = [...billingAddress];

                    if (!order) {
                        if (customerId) {
                            data.id = await updateBillingAddress(customerId, data);
                            newBillingAddress.push(data);
                        }
                    } else {
                        newBillingAddress.push(data);
                        uiStore.dismissPanel({
                            data: {
                                billingAddresses: newBillingAddress,
                                selectedIndex: newBillingAddress.length - 1
                            }
                        });
                    }

                    setBillingAddress(() => newBillingAddress)
                }
            })
        },
        [uiStore, billingAddress, customerId, order]
    )

    React.useEffect(
        () => {
            uiStore.updatePanel({
                subtitleComponent: () => (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {
                            isShipping ?
                                <ButtonComponent
                                    variant="outlined"
                                    color={'secondary'}
                                    style={{ marginRight: '0.5rem' }}
                                    onClick={addShippingAddress}
                                >
                                    Adauga adresa livrare
                                </ButtonComponent>
                                :
                                <ButtonComponent
                                    variant="outlined"
                                    color={'secondary'}
                                    style={{ marginRight: '0.5rem' }}
                                    onClick={addBillingAddress}
                                >
                                    Adauga adresa facturare
                                </ButtonComponent>
                        }
                    </div>
                )
            })

        },
        [uiStore.updatePanel, addShippingAddress, shippingAddress, uiStore, addBillingAddress, isShipping]
    )

    const actionDeleteShippingAddress = React.useCallback(
        async (ev, addressId) => {
            const response = await uiStore.openDialog({
                title: "Stergi adresa...",
                message: "Esti sigur ca vrei sa continui?",
            });

            if (response.value !== DialogResponseTypeEnum.Confirm) return;
            try {
                if (!shippingAddress) return;

                const newShippingAddress = [...shippingAddress];

                let index = newShippingAddress.findIndex(o => o.id === addressId);
                if (index < 0) return;

                if (addressId && customerId)
                    await deleteShippingAddress(customerId, addressId);

                newShippingAddress.splice(index, 1);
                setShippingAddress(() => newShippingAddress);

                toast.success('Adresa a fost stearsa cu succes');
            }
            catch (e: any) {
                toast.error(e.message);
            }
        },
        [uiStore, shippingAddress, customerId]
    );

    const actionDeleteBillingAddress = React.useCallback(
        async (ev, addressId) => {
            const response = await uiStore.openDialog({
                title: "Stergi adresa...",
                message: "Esti sigur ca vrei sa continui?",
            });

            if (response.value !== DialogResponseTypeEnum.Confirm) return;
            try {
                if (!billingAddress) return;

                const newBillingAddress = [...billingAddress];

                let index = newBillingAddress.findIndex(o => o.id === addressId);
                if (index < 0) return;

                if (addressId && customerId)
                    await deleteBillingAddress(customerId, addressId);

                newBillingAddress.splice(index, 1);
                setBillingAddress(() => newBillingAddress);

                toast.success('Adresa a fost stearsa cu succes');
            }
            catch (e: any) {
                toast.error(e.message);
            }
        },
        [uiStore, customerId, billingAddress]
    );

    return (
        <ListComponentStyled>
            <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
                <List component="nav" aria-label="main mailbox folders">

                    {shippingAddress?.map((address, index) => (
                        <Grid key={index} container spacing={0}>
                            <Grid item xs={order ? 12 : 11}>
                                <ListItemButton
                                    key={index}
                                    selected={selectedIndex === index}
                                    onClick={(event) => handleListItemClick(event, index)}
                                >
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <ListItemText
                                            primary={[address.firstName, address.lastName].filter(f => f).join(' ')}
                                        />
                                        <ListItemText
                                            disableTypography={true}
                                            sx={{
                                                color: themeVariables.colors.primary,
                                                fontWeight: themeVariables.fontWeights.bold,
                                                fontSize: themeVariables.fontSizes.subtitle
                                            }}
                                            secondary={[address.phoneNumber].filter(f => f).join(' ')}
                                        />
                                        <ListItemText
                                            secondary={[address.city, address.county, address.postalCode, address.street, address.streetNumber, address.flat, address.building, address.floor, address.apartmentNumber].filter(f => f).join(', ')}
                                        />
                                        <ListItemText
                                            secondary={[address.companyName, address.vatCode, address.regCode].filter(f => f).join(' ')}
                                        />
                                    </div>
                                </ListItemButton>
                            </Grid>
                            {
                                order ? null :
                                    <Grid item xs={1}
                                        display="flex"
                                        justifyContent="center">
                                        <IconButton onClick={(event) => actionDeleteShippingAddress(event, address.id)} type="submit">
                                            <DeleteOutlineIcon />
                                        </IconButton>
                                    </Grid>
                            }

                        </Grid>
                    ))}

                    {billingAddress?.map((address, index) => (
                        <Grid container spacing={0}>
                            <Grid item xs={order ? 12 : 11}>
                                <ListItemButton
                                    key={index}
                                    selected={selectedIndex === index}
                                    onClick={(event) => handleListItemClick(event, index)}
                                >
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <ListItemText
                                            primary={[address.firstName, address.lastName].filter(f => f).join(' ')}
                                        />
                                        <ListItemText
                                            disableTypography={true}
                                            sx={{
                                                color: themeVariables.colors.primary,
                                                fontWeight: themeVariables.fontWeights.bold,
                                                fontSize: themeVariables.fontSizes.subtitle
                                            }}
                                            secondary={[address.phoneNumber, address.companyName].filter(f => f).join(' ')}
                                        />
                                        <ListItemText
                                            secondary={[address.city, address.county].filter(f => f).join(', ')}
                                        />
                                        <ListItemText
                                            secondary={[address.otherDetails].filter(f => f).join(' ')}
                                        />
                                    </div>
                                </ListItemButton>
                            </Grid>
                            {
                                order ? null :
                                    <Grid item xs={1}
                                        display="flex"
                                        justifyContent="center">
                                        <IconButton onClick={(event) => actionDeleteBillingAddress(event, address.id)} type="submit">
                                            <DeleteOutlineIcon />
                                        </IconButton>
                                    </Grid>
                            }
                        </Grid>
                    ))}
                </List>

            </Box>
        </ListComponentStyled>
    );
}
