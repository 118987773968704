import { Box, Grid, IconButton, InputAdornment, LinearProgress } from "@mui/material";
import { AddressTypeEnum } from "../../enums/address-type.enum";
import { AddressType, BillingAddressType, ShippingAddressType } from "../../types/address.type";
import React, { useCallback, useEffect, useState } from "react";
import { useStore } from "../../hooks/store.hook";
import { StateFieldType } from "../../types/form.types";
import ButtonComponent from "../button/button.component";
import FormSectionComponent from "../form-section/form-section.component";
import TextFieldComponent from "../text-field/text-field.component";
import { AddressStyled } from "./address.component.styled";
import SearchIcon from '@mui/icons-material/Search';
import { getAnaf } from "../../utils/requests";
import AsyncSelectComponent, { AsyncSelectOptionType } from "../async-select/async-select.component";
import { DictionaryType } from "../../types/dictionary.type";
import toast from "react-hot-toast";
import { getRequest } from "../../utils/http-requests";
import { CustomerType } from "../../types/customer.type";

type FormAddressStateType = {
    fields: {
        street: StateFieldType<string>;
        streetNumber: StateFieldType<string>;
        flat: StateFieldType<string>;
        building: StateFieldType<string>;
        floor: StateFieldType<string>;
        apartmentNumber: StateFieldType<string>;
        postalCode: StateFieldType<string>

        county: StateFieldType<DictionaryType>;
        city: StateFieldType<DictionaryType>;
    },
}

type FormShippingAddressStateType = {
    fields: {
        id: StateFieldType<string>;
        phoneNumber: StateFieldType<string>;
        firstName: StateFieldType<string>;
        lastName: StateFieldType<string>;
        companyName: StateFieldType<string>;

        // shipping address
        street: StateFieldType<string>;
        streetNumber: StateFieldType<string>;
        flat: StateFieldType<string>;
        building: StateFieldType<string>;
        floor: StateFieldType<string>;
        apartmentNumber: StateFieldType<string>;
        postalCode: StateFieldType<string>;

        vatCode: StateFieldType<string>;
        regCode: StateFieldType<string>;

        county: StateFieldType<DictionaryType>;
        city: StateFieldType<DictionaryType>;

    },
}

type FormBillingAddressStateType = {
    fields: {
        id: StateFieldType<string>;
        phoneNumber: StateFieldType<string>;
        firstName: StateFieldType<string>;
        lastName: StateFieldType<string>;
        companyName: StateFieldType<string>;

        // billing address
        county: StateFieldType<DictionaryType>;
        city: StateFieldType<DictionaryType>;

        vatCode: StateFieldType<string>;
        regCode: StateFieldType<string>;
        otherDetails: StateFieldType<string>;
    },
}

type AddressPropsType = {
    type: AddressTypeEnum;
    editInfo?: AddressType;
    editShippingAddressInfo?: ShippingAddressType;
    editBillingAddressInfo?: BillingAddressType;
    customer?: CustomerType | null;
}

const getInitState = (type: AddressTypeEnum): FormAddressStateType | FormShippingAddressStateType | FormBillingAddressStateType => {
    switch (type) {
        case AddressTypeEnum.Normal:
            return {
                fields: {
                    street: {
                        value: '',
                        isValid: false,
                    },
                    streetNumber: {
                        value: '',
                        isValid: false,
                    },
                    flat: {
                        value: '',
                        isValid: true,
                        noValidation: true,
                    },
                    building: {
                        value: '',
                        noValidation: true,
                        isValid: true
                    },
                    floor: {
                        value: '',
                        noValidation: true,
                        isValid: true
                    },
                    apartmentNumber: {
                        value: '',
                        noValidation: true,
                        isValid: true
                    },
                    county: {
                        value: { label: '' },
                        isValid: false,
                    },
                    city: {
                        value: { label: '' },
                        isValid: false,
                    },
                    postalCode: {
                        value: '',
                        isValid: false,
                    },
                }
            } as FormAddressStateType

        case AddressTypeEnum.Shipping:
            return {
                fields: {
                    id: {
                        value: '',
                        isValid: true,
                        noValidation: true
                    },
                    firstName: {
                        value: '',
                        isValid: false,
                        errorMessage: 'Furnizati un prenume valid'
                    },
                    lastName: {
                        value: '',
                        isValid: false,
                        errorMessage: 'Furnizati un nume valid'
                    },
                    phoneNumber: {
                        value: '',
                        isValid: false,
                        errorMessage: 'Furnizati un numar de telefon valid'
                    },
                    companyName: {
                        value: '',
                        noValidation: true,
                        isValid: true
                    },
                    street: {
                        value: '',
                        isValid: false,
                    },
                    streetNumber: {
                        value: '',
                        isValid: false,
                    },
                    flat: {
                        value: '',
                        isValid: true,
                        noValidation: true,
                    },
                    building: {
                        value: '',
                        noValidation: true,
                        isValid: true
                    },
                    floor: {
                        value: '',
                        noValidation: true,
                        isValid: true
                    },
                    apartmentNumber: {
                        value: '',
                        noValidation: true,
                        isValid: true
                    },
                    vatCode: {
                        value: '',
                        isValid: true,
                        noValidation: true
                    },
                    regCode: {
                        value: '',
                        isValid: true,
                        noValidation: true
                    },
                    county: {
                        value: { label: '' },
                        isValid: false,
                    },
                    city: {
                        value: { label: '' },
                        isValid: false,
                    },
                    postalCode: {
                        value: '',
                        isValid: false,
                    },
                }
            } as FormShippingAddressStateType

        case AddressTypeEnum.Billing:
            return {
                fields: {
                    id: {
                        value: '',
                        isValid: true,
                        noValidation: true
                    },
                    firstName: {
                        value: '',
                        isValid: false,
                        errorMessage: 'Furnizati un prenume valid'
                    },
                    lastName: {
                        value: '',
                        isValid: false,
                        errorMessage: 'Furnizati un nume valid'
                    },
                    phoneNumber: {
                        value: '',
                        isValid: false,
                        errorMessage: 'Furnizati un numar de telefon valid'
                    },
                    companyName: {
                        value: '',
                        noValidation: true,
                        isValid: true
                    },
                    county: {
                        value: { label: '' },
                        isValid: false,
                    },
                    city: {
                        value: { label: '' },
                        isValid: false,
                    },
                    vatCode: {
                        value: '',
                        isValid: true,
                        noValidation: true
                    },
                    regCode: {
                        value: '',
                        isValid: true,
                        noValidation: true
                    },
                    otherDetails: {
                        value: '',
                        noValidation: true,
                        isValid: true
                    },
                }
            }
    }
}

const Address = ({
    type,
    editInfo,
    editShippingAddressInfo,
    editBillingAddressInfo,
    customer
}: AddressPropsType) => {

    const [contorFirmData, setContorFirmData] = useState(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [state, setState] = useState(getInitState(type))
    const [optionsCounties, setOptionsCounties] = useState<AsyncSelectOptionType[]>([]);
    const [optionsCity, setOptionsCity] = useState<AsyncSelectOptionType[]>([]);
    const uiStore = useStore('uiStore');

    const updateStateField = useCallback(
        (currentField: StateFieldType<any>, newValue: any): StateFieldType<any> => {
            return {
                ...currentField,
                isValid: (currentField.validator ? (currentField.validator as any)(newValue) : !!newValue) ||
                    currentField.noValidation,
                value: newValue
            }
        },
        []
    )
    const updateIsValid = useCallback(
        (currentField: StateFieldType<any>, newValue: any): StateFieldType<any> => {
            return {
                ...currentField,
                isValid: newValue,
            }
        },
        []
    )

    const optionIsValid = useCallback(
        (value: AsyncSelectOptionType, options: any[]) => {
            
            const indexValue = options.map( m => m.label === value?.label)
            const isNotValid = indexValue.some(field => field);
            
            return isNotValid
        },
        []
    )

    useEffect(
        () => {
            if(customer){
                setState((m: any) => ({
                    ...m,
                    fields: {
                        ...m.fields,
                        phoneNumber: updateStateField(m.fields.phoneNumber, customer.phoneNumber ?? ''),
                        firstName: updateStateField(m.fields.firstName, customer.firstName ?? ''),
                        lastName: updateStateField(m.fields.lastName, customer.lastName ?? ''),
                        companyName: updateStateField(m.fields.companyName, customer.company?.name ?? ''),
                        vatCode: updateStateField(m.fields.vatCode, customer.company?.vatCode ?? ''),
                        regCode: updateStateField(m.fields.regCode, customer.company?.regCode ?? '')
                    }
                }))
            }
        },
        [
            customer, 
            updateStateField
        ]
    )

    useEffect(
        () => {
            if (editInfo) {
                setState((m: any) => ({
                    ...m,
                    fields: {
                        ...m.fields,
                        street: updateStateField(m.fields.street, editInfo.street ?? ''),
                        streetNumber: updateStateField(m.fields.streetNumber, editInfo.streetNumber ?? ''),
                        postalCode: updateStateField(m.fields.postalCode, editInfo.postalCode ?? ''),
                        county: updateStateField(m.fields.county, { label: editInfo.county ?? '' }),
                        city: updateStateField(m.fields.city, { label: editInfo.city ?? '' }),
                        flat: updateStateField(m.fields.flat, editInfo.flat ?? ''),
                        floor: updateStateField(m.fields.floor, editInfo.floor ?? ''),
                        building: updateStateField(m.fields.building, editInfo.building ?? ''),
                        apartmentNumber: updateStateField(m.fields.apartmentNumber, editInfo.apartmentNumber ?? ''),
                    }
                }))
            } else if (editShippingAddressInfo) {
                setState((m: any) => ({
                    ...m,
                    fields: {
                        ...m.fields,
                        id: updateStateField(m.fields.id, editShippingAddressInfo.id ?? ''),
                        street: updateStateField(m.fields.street, editShippingAddressInfo.street ?? ''),
                        streetNumber: updateStateField(m.fields.streetNumber, editShippingAddressInfo.streetNumber ?? ''),
                        postalCode: updateStateField(m.fields.postalCode, editShippingAddressInfo.postalCode ?? ''),
                        county: updateStateField(m.fields.county, { label: editShippingAddressInfo.county ?? '' }),
                        city: updateStateField(m.fields.city, { label: editShippingAddressInfo.city ?? '' }),
                        flat: updateStateField(m.fields.flat, editShippingAddressInfo.flat ?? ''),
                        floor: updateStateField(m.fields.floor, editShippingAddressInfo.floor ?? ''),
                        building: updateStateField(m.fields.building, editShippingAddressInfo.building ?? ''),
                        apartmentNumber: updateStateField(m.fields.apartmentNumber, editShippingAddressInfo.apartmentNumber ?? ''),
                        phoneNumber: updateStateField(m.fields.phoneNumber, editShippingAddressInfo.phoneNumber ?? ''),
                        firstName: updateStateField(m.fields.firstName, editShippingAddressInfo.firstName ?? ''),
                        lastName: updateStateField(m.fields.lastName, editShippingAddressInfo.lastName ?? ''),
                        companyName: updateStateField(m.fields.companyName, editShippingAddressInfo.companyName ?? ''),
                        vatCode: updateStateField(m.fields.vatCode, editShippingAddressInfo.vatCode ?? ''),
                        regCode: updateStateField(m.fields.regCode, editShippingAddressInfo.regCode ?? '')
                    }
                }))
            } else if (editBillingAddressInfo) {

                setState((m: any) => ({
                    ...m,
                    fields: {
                        ...m.fields,
                        id: updateStateField(m.fields.id, editBillingAddressInfo.id ?? ''),
                        county: updateStateField(m.fields.county, { label: editBillingAddressInfo.county ?? '' }),
                        city: updateStateField(m.fields.city, { label: editBillingAddressInfo.city ?? '' }),
                        phoneNumber: updateStateField(m.fields.phoneNumber, editBillingAddressInfo.phoneNumber ?? ''),
                        firstName: updateStateField(m.fields.firstName, editBillingAddressInfo.firstName ?? ''),
                        lastName: updateStateField(m.fields.lastName, editBillingAddressInfo.lastName ?? ''),
                        companyName: updateStateField(m.fields.companyName, editBillingAddressInfo.companyName ?? ''),
                        vatCode: updateStateField(m.fields.vatCode, editBillingAddressInfo.vatCode ?? ''),
                        regCode: updateStateField(m.fields.regCode, editBillingAddressInfo.regCode ?? ''),
                        otherDetails: updateStateField(m.fields.otherDetails, editBillingAddressInfo.otherDetails ?? '')
                    }
                }))
            }

        },
        [
            editBillingAddressInfo,
            editInfo, 
            editShippingAddressInfo, 
            optionIsValid, 
            updateIsValid, 
            updateStateField
        ]
    )
    useEffect(
        () => {
            if(editInfo)
                if(state as FormAddressStateType)
                    if((state as FormAddressStateType).fields.street.value 
                        || (state as FormAddressStateType).fields.apartmentNumber.value
                        || (state as FormAddressStateType).fields.building.value
                        || (state as FormAddressStateType).fields.city.value?.label
                        || (state as FormAddressStateType).fields.county.value?.label
                        || (state as FormAddressStateType).fields.flat.value
                        || (state as FormAddressStateType).fields.floor.value
                        || (state as FormAddressStateType).fields.postalCode.value
                        || (state as FormAddressStateType).fields.streetNumber.value)
                        uiStore.setOrderData(true)
                    else
                        uiStore.setOrderData(false)

            
            if(editBillingAddressInfo )
                if(state as FormBillingAddressStateType)
                    if((state as FormBillingAddressStateType).fields.city.value?.label
                    || (state as FormBillingAddressStateType).fields.county.value?.label
                    || (state as FormBillingAddressStateType).fields.companyName.value
                    || (state as FormBillingAddressStateType).fields.firstName.value
                    || (state as FormBillingAddressStateType).fields.lastName.value
                    || (state as FormBillingAddressStateType).fields.otherDetails.value
                    || (state as FormBillingAddressStateType).fields.phoneNumber.value
                    || (state as FormBillingAddressStateType).fields.regCode.value
                    || (state as FormBillingAddressStateType).fields.vatCode.value)
                        uiStore.setOrderData(true)
                    else
                        uiStore.setOrderData(false)

            if(editShippingAddressInfo)
                if(state as FormShippingAddressStateType)
                    if((state as FormShippingAddressStateType).fields.city.value?.label
                    || (state as FormShippingAddressStateType).fields.county.value?.label
                    || (state as FormShippingAddressStateType).fields.companyName.value
                    || (state as FormShippingAddressStateType).fields.firstName.value
                    || (state as FormShippingAddressStateType).fields.lastName.value
                    || (state as FormShippingAddressStateType).fields.phoneNumber.value
                    || (state as FormShippingAddressStateType).fields.regCode.value
                    || (state as FormShippingAddressStateType).fields.vatCode.value
                    || (state as FormShippingAddressStateType).fields.apartmentNumber.value
                    || (state as FormShippingAddressStateType).fields.building.value
                    || (state as FormShippingAddressStateType).fields.flat.value
                    || (state as FormShippingAddressStateType).fields.floor.value
                    || (state as FormShippingAddressStateType).fields.postalCode.value
                    || (state as FormShippingAddressStateType).fields.street.value
                    || (state as FormShippingAddressStateType).fields.streetNumber.value)
                        uiStore.setOrderData(true)
                    else
                        uiStore.setOrderData(false)

        },
        [
            editBillingAddressInfo, 
            editInfo, 
            editShippingAddressInfo, 
            state, 
            uiStore
        ]
    )
    
    useEffect(
        () => {
            const urlCounties = "/dictionary/getcounties"
            async function getCounties(){
                setIsLoading(true)
                const response = await getRequest({url: urlCounties})
                setOptionsCounties(response.data)
                setIsLoading(false)
            }
            getCounties()
        },
        []
    )

    useEffect(
        () => {
            if(!state.fields.county.value?.label) return
            const urlCities = `/dictionary/getcities/${state.fields.county.value.label}`
            async function getCities(){
                setIsLoading(true)
                const response = await getRequest({url: urlCities})
                setOptionsCity(response.data)
                setIsLoading(false)
            }
            getCities()
        },
        [state.fields.county.value.label]
    )

    useEffect(
        () => {
            if(optionsCounties && optionsCity)
                setState((m: any) => ({
                    ...m,
                    fields: {
                        ...m.fields,
                        county: updateIsValid(m.fields.county, optionIsValid(m.fields.county.value, optionsCounties)),
                        city: updateIsValid(m.fields.city, optionIsValid(m.fields.city.value, optionsCity))
                    }
                }))
        },
        [
            optionIsValid, 
            optionsCity, 
            optionsCounties, 
            updateIsValid
        ]
    )

    const updateState = useCallback(
        (field: string, newValue: any) => {
            setState(state => ({
                ...state,
                fields: {
                    ...state.fields,
                    [field]: updateStateField((state.fields as any)[field], newValue)
                }
            }) as any)
        },
        [updateStateField]
    )

    const renderNormalAddress = () => {
        const variable = state as FormAddressStateType
        return (
            <>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <AsyncSelectComponent
                            label="Judet"
                            options={optionsCounties}
                            value={!variable.fields.county.value?.label ? null : variable.fields.county.value}
                            onOptionChange={e => { updateState('county', e); updateState('city', null); }}
                            isOptionEqualToValue={(option, value) => option.label === value.label}
                            renderOption={(props, option) => (
                                <Box component="li" {...props}>
                                    {[option.label]}
                                </Box>
                            )}
                            error={!variable.fields.county.isValid}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <AsyncSelectComponent
                            label="Oras"
                            options={optionsCity}
                            value={variable.fields.city.value}
                            onOptionChange={e => updateState('city', e)}
                            isOptionEqualToValue={(option, value) => {
                                return option.label === value.label
                            }}
                            renderOption={(props, option) => (
                                <Box component="li" {...props}>
                                    {[option.label]}
                                </Box>
                            )}
                            error={!variable.fields.city.isValid}
                        />
                    </Grid>
                    <Grid item xs={9}>
                        <TextFieldComponent
                            label="Strada"
                            variant="outlined"
                            fullWidth={true}
                            value={variable.fields.street.value}
                            error={!variable.fields.street.isValid}
                            helperText={!variable.fields.street.isValid && variable.fields.street.errorMessage}
                            onTextChange={e => updateState('street', e)}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextFieldComponent
                            label="Nr."
                            variant="outlined"
                            fullWidth={true}
                            value={variable.fields.streetNumber.value}
                            error={!variable.fields.streetNumber.isValid}
                            helperText={!variable.fields.streetNumber.isValid && variable.fields.streetNumber.errorMessage}
                            onTextChange={e => updateState('streetNumber', e)}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextFieldComponent
                            label="Bloc"
                            variant="outlined"
                            fullWidth={true}
                            value={variable.fields.flat.value}
                            error={!variable.fields.flat.isValid}
                            helperText={!variable.fields.flat.isValid && variable.fields.flat.errorMessage}
                            onTextChange={e => updateState('flat', e)}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextFieldComponent
                            label="Scara"
                            variant="outlined"
                            fullWidth={true}
                            value={variable.fields.building.value}
                            error={!variable.fields.building.isValid}
                            helperText={!variable.fields.building.isValid && variable.fields.building.errorMessage}
                            onTextChange={e => updateState('building', e)}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextFieldComponent
                            label="Etaj"
                            variant="outlined"
                            fullWidth={true}
                            value={variable.fields.floor.value}
                            error={!variable.fields.floor.isValid}
                            helperText={!variable.fields.floor.isValid && variable.fields.floor.errorMessage}
                            onTextChange={e => updateState('floor', e)}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextFieldComponent
                            label="Nr. ap."
                            variant="outlined"
                            fullWidth={true}
                            value={variable.fields.apartmentNumber.value}
                            error={!variable.fields.apartmentNumber.isValid}
                            helperText={!variable.fields.apartmentNumber.isValid && variable.fields.apartmentNumber.errorMessage}
                            onTextChange={e => updateState('apartmentNumber', e)}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextFieldComponent
                            label="Cod Postal"
                            variant="outlined"
                            fullWidth={true}
                            value={variable.fields.postalCode.value}
                            error={!variable.fields.postalCode.isValid}
                            helperText={!variable.fields.postalCode.isValid && variable.fields.postalCode.errorMessage}
                            onTextChange={e => updateState('postalCode', e)}
                        />
                    </Grid>

                </Grid>
            </>
        )
    }

    const SearchFirm =
        async () => {
            const variable = state as FormShippingAddressStateType
            try {
                const results = await getAnaf(variable.fields.vatCode.value);

                setState((state: any) => ({
                    ...state,
                    fields: {
                        ...state.fields,
                        companyName: { ...state.fields.companyName, value: results.data.found[0].denumire ?? '', isValid: !!results.data.found[0].denumire },
                        regCode: { ...state.fields.regCode, value: results.data.found[0].nrRegCom ?? '', isValid: !!results.data.found[0].nrRegCom },
                        postalCode: {
                            ...state.fields.postalCode, value: type === AddressTypeEnum.Shipping ? results.data.found[0].codPostal : '',
                            isValid: type === AddressTypeEnum.Shipping ? !!results.data.found[0].codPostal : true
                        }
                    }
                }))
                if (!results.data.found[0].denumire)
                    setContorFirmData(true)
                else
                    setContorFirmData(false)

            } catch (e: any) {
                toast.error(e.message);
            }
        }

    const renderShippingAddress = () => {
        const variable = state as FormShippingAddressStateType

        return (
            <>
                <FormSectionComponent variant="panel" title="Detalii generale">
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextFieldComponent
                                label="Nume"
                                variant="outlined"
                                fullWidth={true}
                                value={variable.fields.firstName.value}
                                error={!variable.fields.firstName.isValid}
                                helperText={!variable.fields.firstName.isValid && variable.fields.firstName.errorMessage}
                                onTextChange={e => updateState('firstName', e)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextFieldComponent
                                label="Prenume"
                                variant="outlined"
                                fullWidth={true}
                                value={variable.fields.lastName.value}
                                error={!variable.fields.lastName.isValid}
                                helperText={!variable.fields.lastName.isValid && variable.fields.lastName.errorMessage}
                                onTextChange={e => updateState('lastName', e)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextFieldComponent
                                label="Numar de telefon"
                                variant="outlined"
                                fullWidth={true}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">+4</InputAdornment>,
                                }}
                                value={variable.fields.phoneNumber.value}
                                error={!variable.fields.phoneNumber.isValid}
                                helperText={!variable.fields.phoneNumber.isValid && variable.fields.phoneNumber.errorMessage}
                                onTextChange={e => updateState('phoneNumber', e)}
                            />
                        </Grid>
                    </Grid>
                </FormSectionComponent>

                <FormSectionComponent variant="panel" title="Detalii companie">
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextFieldComponent
                                label="Introdu Cod fiscal"
                                variant="outlined"
                                fullWidth={true}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                        <IconButton onClick={SearchFirm} type="submit" aria-label="search">
                                            <SearchIcon />
                                        </IconButton>
                                    </InputAdornment>,
                                }}
                                value={variable.fields.vatCode.value}
                                error={!variable.fields.vatCode.isValid}
                                helperText={(!variable.fields.vatCode.isValid && variable.fields.vatCode.errorMessage) || (contorFirmData && "Nu s-au gasit date, verifica CUI-ul.")}
                                onTextChange={e => updateState('vatCode', e)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextFieldComponent
                                label="Companie"
                                variant="outlined"
                                fullWidth={true}
                                value={variable.fields.companyName.value}
                                error={!variable.fields.companyName.isValid}
                                helperText={!variable.fields.companyName.isValid && variable.fields.companyName.errorMessage}
                                onTextChange={e => updateState('companyName', e)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextFieldComponent
                                label="Nr Reg. Comertului"
                                variant="outlined"
                                fullWidth={true}
                                value={variable.fields.regCode.value}
                                error={!variable.fields.regCode.isValid}
                                helperText={!variable.fields.regCode.isValid && variable.fields.regCode.errorMessage}
                                onTextChange={e => updateState('regCode', e)}
                            />
                        </Grid>
                    </Grid>
                </FormSectionComponent>

                <FormSectionComponent variant="panel" title="Adresa">
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <AsyncSelectComponent
                                label="Judet"
                                options={optionsCounties}
                                value={!variable.fields.county.value?.label ? null : variable.fields.county.value}
                                onOptionChange={e => { updateState('county', e); updateState('city', null); }}
                                isOptionEqualToValue={(option, value) => {
                                    
                                    return option.label === value.label
                                }}
                                renderOption={(props, option) => (
                                    <Box component="li" {...props}>
                                        {[option.label]}
                                    </Box>
                                )}
                                error={!variable.fields.county.isValid}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <AsyncSelectComponent
                                label="Oras"
                                options={optionsCity}
                                value={variable.fields.city.value}
                                onOptionChange={e => updateState('city', e)}
                                isOptionEqualToValue={(option, value) => {
                                    
                                    return option.label === value.label
                                }}
                                renderOption={(props, option) => {
                                    return (
                                        <Box component="li" {...props}>
                                            {[option.label]}
                                        </Box>
                                    )
                                }}
                                error={!variable.fields.city.isValid}
                            />
                        </Grid>
                        <Grid item xs={9}>
                            <TextFieldComponent
                                label="Strada"
                                variant="outlined"
                                fullWidth={true}
                                value={variable.fields.street.value}
                                error={!variable.fields.street.isValid}
                                helperText={!variable.fields.street.isValid && variable.fields.street.errorMessage}
                                onTextChange={e => updateState('street', e)}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextFieldComponent
                                label="Nr."
                                variant="outlined"
                                fullWidth={true}
                                value={variable.fields.streetNumber.value}
                                error={!variable.fields.streetNumber.isValid}
                                helperText={!variable.fields.streetNumber.isValid && variable.fields.streetNumber.errorMessage}
                                onTextChange={e => updateState('streetNumber', e)}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextFieldComponent
                                label="Bloc"
                                variant="outlined"
                                fullWidth={true}
                                value={variable.fields.flat.value}
                                error={!variable.fields.flat.isValid}
                                helperText={!variable.fields.flat.isValid && variable.fields.flat.errorMessage}
                                onTextChange={e => updateState('flat', e)}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextFieldComponent
                                label="Scara"
                                variant="outlined"
                                fullWidth={true}
                                value={variable.fields.building.value}
                                error={!variable.fields.building.isValid}
                                helperText={!variable.fields.building.isValid && variable.fields.building.errorMessage}
                                onTextChange={e => updateState('building', e)}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextFieldComponent
                                label="Etaj"
                                variant="outlined"
                                fullWidth={true}
                                value={variable.fields.floor.value}
                                error={!variable.fields.floor.isValid}
                                helperText={!variable.fields.floor.isValid && variable.fields.floor.errorMessage}
                                onTextChange={e => updateState('floor', e)}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextFieldComponent
                                label="Nr. ap."
                                variant="outlined"
                                fullWidth={true}
                                value={variable.fields.apartmentNumber.value}
                                error={!variable.fields.apartmentNumber.isValid}
                                helperText={!variable.fields.apartmentNumber.isValid && variable.fields.apartmentNumber.errorMessage}
                                onTextChange={e => updateState('apartmentNumber', e)}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextFieldComponent
                                label="Cod Postal"
                                variant="outlined"
                                fullWidth={true}
                                value={variable.fields.postalCode.value}
                                error={!variable.fields.postalCode.isValid}
                                helperText={!variable.fields.postalCode.isValid && variable.fields.postalCode.errorMessage}
                                onTextChange={e => updateState('postalCode', e)}
                            />
                        </Grid>
                    </Grid>
                </FormSectionComponent>


            </>
        )
    }

    const renderBillingAddress = () => {
        const variable = state as FormBillingAddressStateType

        return (
            <>
                <FormSectionComponent variant="panel" title="Detalii contact">
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextFieldComponent
                                label="Nume"
                                variant="outlined"
                                fullWidth={true}
                                value={variable.fields.firstName.value}
                                error={!variable.fields.firstName.isValid}
                                helperText={!variable.fields.firstName.isValid && variable.fields.firstName.errorMessage}
                                onTextChange={e => updateState('firstName', e)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextFieldComponent
                                label="Prenume"
                                variant="outlined"
                                fullWidth={true}
                                value={variable.fields.lastName.value}
                                error={!variable.fields.lastName.isValid}
                                helperText={!variable.fields.lastName.isValid && variable.fields.lastName.errorMessage}
                                onTextChange={e => updateState('lastName', e)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextFieldComponent
                                label="Numar de telefon"
                                variant="outlined"
                                fullWidth={true}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">+4</InputAdornment>,
                                }}
                                value={variable.fields.phoneNumber.value}
                                error={!variable.fields.phoneNumber.isValid}
                                helperText={!variable.fields.phoneNumber.isValid && variable.fields.phoneNumber.errorMessage}
                                onTextChange={e => updateState('phoneNumber', e)}
                            />
                        </Grid>
                    </Grid>
                </FormSectionComponent>

                <FormSectionComponent variant="panel" title="Detalii companie">
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextFieldComponent
                                label="Introdu Cod fiscal"
                                variant="outlined"
                                fullWidth={true}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                        <IconButton onClick={SearchFirm} type="submit" aria-label="search">
                                            <SearchIcon />
                                        </IconButton>
                                    </InputAdornment>,
                                }}
                                value={variable.fields.vatCode.value}
                                error={!variable.fields.vatCode.isValid}
                                helperText={(!variable.fields.vatCode.isValid && variable.fields.vatCode.errorMessage) || (contorFirmData && "Nu s-au gasit date, verifica CUI-ul.")}
                                onTextChange={e => updateState('vatCode', e)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextFieldComponent
                                label="Companie"
                                variant="outlined"
                                fullWidth={true}
                                value={variable.fields.companyName.value}
                                error={!variable.fields.companyName.isValid}
                                helperText={!variable.fields.companyName.isValid && variable.fields.companyName.errorMessage}
                                onTextChange={e => updateState('companyName', e)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextFieldComponent
                                label="Nr Reg. Comertului"
                                variant="outlined"
                                fullWidth={true}
                                value={variable.fields.regCode.value}
                                error={!variable.fields.regCode.isValid}
                                helperText={!variable.fields.regCode.isValid && variable.fields.regCode.errorMessage}
                                onTextChange={e => updateState('regCode', e)}
                            />
                        </Grid>
                    </Grid>
                </FormSectionComponent>

                <FormSectionComponent variant="panel" title="Adresa">
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <AsyncSelectComponent
                                label="Judet"
                                options={optionsCounties}
                                value={!variable.fields.county.value?.label ? null : variable.fields.county.value}
                                onOptionChange={e => { updateState('county', e); updateState('city', null); }}
                                isOptionEqualToValue={(option, value) => option.label === value.label}
                                renderOption={(props, option) => (
                                    <Box component="li" {...props}>
                                        {[option.label]}
                                    </Box>
                                )}
                                error={!variable.fields.county.isValid}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <AsyncSelectComponent
                                label="Oras"
                                options={optionsCity}
                                value={variable.fields.city.value}
                                onOptionChange={e => updateState('city', e)}
                                isOptionEqualToValue={(option, value) => option.label === value.label}
                                renderOption={(props, option) => {
                                    return (
                                        <Box component="li" {...props}>
                                            {[option.label]}
                                        </Box>
                                    )}}
                                error={!variable.fields.city.isValid}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextFieldComponent
                                label="Alte Detalii"
                                variant="outlined"
                                multiline
                                rows={2}
                                fullWidth={true}
                                value={variable.fields.otherDetails.value}
                                error={!variable.fields.otherDetails.isValid}
                                helperText={!variable.fields.otherDetails.isValid && variable.fields.otherDetails.errorMessage}
                                onTextChange={e => updateState('otherDetails', e)}
                            />
                        </Grid>
                    </Grid>
                </FormSectionComponent>
            </>
        )
    }

    const addAddress = useCallback(
        async () => {
            const variable = state as any
            var data;

            const isNotValid = Object.values(state.fields).some(field => !field.isValid);
            if (isNotValid) return;


            if (type === AddressTypeEnum.Normal)
                data = {
                    street: variable?.fields.street.value,
                    streetNumber: variable?.fields.streetNumber.value,
                    flat: variable?.fields.flat.value,
                    building: variable?.fields.building.value,
                    floor: variable?.fields.floor.value,
                    apartmentNumber: variable?.fields.apartmentNumber.value,
                    county: variable?.fields.county.value?.label,
                    city: variable?.fields.city.value?.label,
                    postalCode: variable?.fields.postalCode.value,
                } as AddressType
            else if (type === AddressTypeEnum.Shipping)
                data = {
                    id: variable?.fields.id?.value,
                    street: variable?.fields.street.value,
                    streetNumber: variable?.fields.streetNumber.value,
                    flat: variable?.fields.flat.value,
                    building: variable?.fields.building.value,
                    floor: variable?.fields.floor.value,
                    apartmentNumber: variable?.fields.apartmentNumber.value,
                    county: variable?.fields.county.value?.label,
                    city: variable?.fields.city.value?.label,
                    postalCode: variable?.fields.postalCode.value,
                    phoneNumber: variable?.fields.phoneNumber.value,
                    firstName: variable?.fields.firstName.value,
                    lastName: variable?.fields.lastName.value,
                    companyName: variable?.fields.companyName.value,
                    vatCode: variable?.fields.vatCode.value,
                    regCode: variable?.fields.regCode.value,
                }
            else if (type === AddressTypeEnum.Billing)
                data = {
                    id: variable?.fields.id?.value,
                    county: variable?.fields.county.value?.label,
                    city: variable?.fields.city.value?.label,
                    phoneNumber: variable?.fields.phoneNumber.value,
                    firstName: variable?.fields.firstName.value,
                    lastName: variable?.fields.lastName.value,
                    companyName: variable?.fields.companyName.value,
                    vatCode: variable?.fields.vatCode.value,
                    regCode: variable?.fields.regCode.value,
                    otherDetails: variable?.fields.otherDetails.value,
                }

            uiStore.dismissPanel({ data: data });
        },
        [state, type, uiStore]
    )

    return (
        <>
            {
                isLoading ? 
                <LinearProgress style={{borderRadius: '10px'}} />
                : 
                <AddressStyled>
                    {type === AddressTypeEnum.Normal ? renderNormalAddress() : ''}
                    {type === AddressTypeEnum.Shipping ? renderShippingAddress() : ''}
                    {type === AddressTypeEnum.Billing ? renderBillingAddress() : ''}
                    <div className="button-container">
                        <ButtonComponent onClick={addAddress}>
                            Salveaza adresa
                        </ButtonComponent>
                    </div>
                </AddressStyled>
            }
        </>
    )
}

export default Address;