import { CancelOutlined, IosShare, Tag, Description, LocalShipping, Paid, LocalPostOffice, Person } from '@mui/icons-material';
import { Chip, Divider, Grid } from '@mui/material';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { AwbTypeEnum } from '../../enums/awb-type.enum';
import { CurrencyEnum } from '../../enums/currency.enum';
import { DialogResponseTypeEnum } from '../../enums/dialog-response-type.enum';
import { HeaderTypeEnum } from '../../enums/header-type.enum';
import { OrderStatusEnum } from '../../enums/order-status.enum';
import { PanelType } from '../../enums/panel-type.enum';
import { TableTypeEnum } from '../../enums/table-type.enum';
import { useStore } from '../../hooks/store.hook';
import { AwbType, InvoiceType, OrderDetailsLineItemType, OrderType } from '../../types/order.type';
import { cancelOrder, downloadAwb, issueOrder } from '../../utils/requests';
import { formatDate } from '../../utils/utils';
import AssignAwbComponent from '../assign-awb/assign-awb.component';
import ButtonComponent from '../button/button.component';
import EditPaymentComponent from '../edit-payment/edit.payment.component';
import FormSectionComponent from '../form-section/form-section.component';
import FrameViewerComponent from '../frame-viewer/frame-viewer.component';
import OrderDetailsHeaderComponent from '../order-details-header/order-details-header.component';
import OrderTotalFooterComponent from '../order-total-footer/order-total-footer.component';
import TableComponent, { CustomRendererPropsType, TableDataPropsType } from '../table/table.component';
import OrderDetailsSummaryTabComponentStyled from './order-details-summary-tab.component.styled';
import { LineItemType } from '../../types/order.type';
import { PaymentTypeEnum } from "../../enums/payment-type.enum";
import CustomerPanelComponent from '../customer-panel/customer-panel.component';
import PanelItemComponent, { PanelItemIconComponent } from '../panel-item/panel-item.component';
import { CustomerType } from '../../types/customer.type';
import TooltipComponent from '../tooltip/tooltip.component';

const AwbTypeToDescription: { [key in AwbTypeEnum]: string } = {
    [AwbTypeEnum.NemoExpress]: 'Nemo',
    [AwbTypeEnum.Dpd]: 'Dpd'
}

export type OrderDetailsSummaryTabComponentPropsType = {
    orderDetails?: OrderType,
    onOrderDetailsChange: (updatedOrder: OrderType) => void
}

const OrderDetailsSummaryTabComponent = ({
    orderDetails,
    onOrderDetailsChange
}: OrderDetailsSummaryTabComponentPropsType) => {

    const uiStore = useStore('uiStore');
    const tableStore = useStore('tableStore');

    const [isIssuing, setIsIssuing] = useState<boolean>(false);
    const [isCanceling, setIsCanceling] = useState<boolean>(false);

    const tableData = useMemo(
        (): TableDataPropsType => {
            return {
                data: (orderDetails?.lineItems ?? []).map<OrderDetailsLineItemType>((m, index) => ({
                    ...m,
                    id: m.sku,
                    items: m.childrenItems
                })),
                headers: [
                    {
                        id: 'description',
                        label: 'Descriere',
                        alignment: 'left',
                        headerType: HeaderTypeEnum.String,
                        sortable: false,
                    },
                    {
                        id: 'awbs',
                        label: 'Awb',
                        alignment: 'center',
                        headerType: HeaderTypeEnum.String,
                        sortable: false,
                    },
                    {
                        id: 'quantity',
                        label: 'Cant.',
                        alignment: 'center',
                        headerType: HeaderTypeEnum.String,
                        sortable: false,
                    },
                    {
                        id: 'unitPrice',
                        label: 'Pret Un. / Discount',
                        alignment: 'right',
                        headerType: HeaderTypeEnum.String,
                        sortable: false,
                    },
                    {
                        id: 'price',
                        label: 'Total',
                        alignment: 'right',
                        headerType: HeaderTypeEnum.String,
                        sortable: false,
                    }
                ]
            }
        },
        [orderDetails?.lineItems]
    )

    const customRenderer: CustomRendererPropsType = {
        description: (row: OrderDetailsLineItemType) => {
            return (
                <TooltipComponent title={row.sku} description={row.title} />
            )
        },
        awbs: (row: OrderDetailsLineItemType) => (
            <>
                {
                    isEmpty(row.items) ?
                        (row.awbTypes || []).map(awbType => (
                            <Chip
                                key={awbType} 
                                size="small"
                                style={{marginRight: '0.3rem'}} 
                                label={AwbTypeToDescription[awbType]} 
                            />
                        )) :
                        '-'
                }
            </>
        ),
        price: (row: OrderDetailsLineItemType) => {
            return (
                <div className='total-price-wrapper'>
                    {
                        (row?.discount || 0) > 0 ? (
                                <div className="price-without-discount">
                                    <div className="line-throught"></div>
                                    {((row?.price || 0) + (row.discount*row.quantity)).toFixed(2)} {CurrencyEnum.RON}
                                </div>
                            ) :
                            ''
                    }
                    <div>{(row?.price || 0).toFixed(2)} {CurrencyEnum.RON}</div>
                </div>
            )
        },
        unitPrice:  (row: OrderDetailsLineItemType) => {
            return (
                <div>
                    <div>{row.unitPrice?.toFixed(2) || '0'} {CurrencyEnum.RON}</div>
                    <div className="product-name"> { row.discount?.toFixed(2) || '0' } {CurrencyEnum.RON} </div>
                </div>
            )
        }
    };

    const onViewInvoice = useCallback(
        () => {
            if (!orderDetails?.invoice) return;

            const invoice: InvoiceType = orderDetails.invoice;
            uiStore.openModal({
                component: <FrameViewerComponent url={invoice.invoiceUrl} />,
                title: `Factura ${invoice.series} ${invoice.number}`
            });
        },
        [orderDetails?.invoice, uiStore]
    )

    const getNameOrEmail = useCallback(
        () => {
            if(orderDetails?.customer)
                if((orderDetails?.customer as CustomerType).fullName){
                    return (orderDetails?.customer as CustomerType).fullName
                } else {
                    return (orderDetails?.customer as CustomerType).email
                }
        },
        [orderDetails?.customer]
    )

    const onViewCustomer = useCallback(
        () => {
            uiStore.openPanel({
                component: <CustomerPanelComponent customerObj={(orderDetails?.customer as CustomerType)} title={`${getNameOrEmail()}`} />,
                key: PanelType.ViewClient,
                onDismiss: (data: any) => {
                    if (!data) return;
                }
            })
        },
        [uiStore, orderDetails?.customer, getNameOrEmail]
    )

    const onPaymentEdit = useCallback(
        () => {
            uiStore.openPanel({
                key: PanelType.EditOrderPayment,
                title: 'Status plata',
                onDismiss: ((updatedOrderDetails?: OrderType) => {
                    if (!updatedOrderDetails) return;
                    onOrderDetailsChange(updatedOrderDetails);
                }),
                component: <EditPaymentComponent orderDetails={orderDetails} />
            })
        },
        [uiStore, orderDetails, onOrderDetailsChange]
    )

    const getInvoiceDateText = useCallback(
        (): string => {
            if (orderDetails?.status === OrderStatusEnum.Canceled)
                return `Anulata pe ${formatDate(orderDetails?.invoice?.canceledAt ?? new Date())}`;
            else if (orderDetails?.invoice?.reversedAt)
                return `Stornata pe ${formatDate(orderDetails?.invoice?.reversedAt ?? new Date())}`;

            return `Emisa pe ${formatDate(orderDetails?.invoice?.issuedAt ?? new Date())}`;
        },
        [orderDetails]
    )

    const onViewAwb = useCallback(
        (awb: AwbType) => {
            downloadAwb(awb.type, orderDetails?.id ?? '')
                .then((content: string) => {
                    uiStore.openModal({
                        component: <FrameViewerComponent url={content} />,
                        title: `Awb ${awb.number}`
                    })
                })
                .catch((e: any) => {
                    toast.error(e.message);
                })
        },
        [orderDetails?.id, uiStore]
    )

    const onIssueOrder = useCallback(
        () => {
            setIsIssuing(() => true);
            issueOrder(orderDetails?.id ?? '')
                .then(updatedOrder => {
                    onOrderDetailsChange(updatedOrder);
                    tableStore.updateTable(TableTypeEnum.Orders);
                    toast.success('Factura a fost emisa cu succes');
                })  
                .catch((e: any) => {
                    toast.error(e.message);
                })
                .finally(() => {
                    setIsIssuing(() => false);
                })
        },
        [orderDetails?.id, onOrderDetailsChange, tableStore]
    )

    const onCancelOrder = useCallback(
        () => {
            setIsCanceling(() => true);
            cancelOrder(orderDetails?.id ?? '')
                .then(updatedOrder => {
                    onOrderDetailsChange(updatedOrder);
                    tableStore.updateTable(TableTypeEnum.Orders);
                    toast.success('Comanda a fost anulata cu succes');
                })  
                .catch((e: any) => {
                    toast.error(e.message);
                })
                .finally(() => {
                    setIsCanceling(() => false);
                })
        },
        [orderDetails?.id, onOrderDetailsChange, tableStore]
    )

    const onAwbGenerate = useCallback(
        async () => {
            let assignAwbWithoutInvoiceReason: string | undefined = undefined;

            if (!orderDetails?.invoice && !orderDetails?.assignAwbWithoutInvoiceReason) {
                const resp = await uiStore.openDialog({
                    title: 'Generare AWB',
                    message: 'Esti sigur ca vrei sa generezi AWB fara sa ai factura emisa pentru aceasta comanda?',
                    withReason: true
                })
                if (resp.value !== DialogResponseTypeEnum.Confirm) return;
                assignAwbWithoutInvoiceReason = resp.reason;
            }

            uiStore.openPanel({
                key: PanelType.AssignAwb,
                component: <AssignAwbComponent
                    orderDetails={orderDetails} 
                    assignAwbWithoutInvoiceReason={assignAwbWithoutInvoiceReason}
                />,
                title: 'Genereaza AWB',
                panelWidth: '700px',
                onDismiss: (data) => {
                    if (!data) return;
                    onOrderDetailsChange(data as OrderType);
                    tableStore.updateTable(TableTypeEnum.Orders);
                }
            });
        },
        [orderDetails, uiStore, onOrderDetailsChange, tableStore]
    )

    const getActionsByOrderStatus = useCallback(
        (): { component: React.ReactNode, canDisplay: any }[] => {
            const items = [
                {
                    component: (
                        <ButtonComponent 
                            color="secondary"
                            variant="outlined"
                            style={{marginRight: '0.5rem'}}
                            startIcon={<IosShare />}
                            isLoading={isIssuing}
                            onClick={onIssueOrder}
                        >
                            Emite factura
                        </ButtonComponent>
                    ),
                    canDisplay: () => {
                        return [OrderStatusEnum.Draft, OrderStatusEnum.AwbGenerated].includes(orderDetails?.status || OrderStatusEnum.Draft) && !orderDetails?.invoice 
                    }
                },
                {
                    component: (
                        <ButtonComponent 
                            color="secondary"
                            variant="outlined" 
                            style={{marginRight: '0.5rem'}}
                            startIcon={<Tag />}
                            onClick={onAwbGenerate}
                        >
                            Genereaza AWB
                        </ButtonComponent>
                    ),
                    canDisplay: () => {
                        return [OrderStatusEnum.Draft, OrderStatusEnum.Issued].includes(orderDetails?.status || OrderStatusEnum.Draft)
                    }
                },
                {
                    component: (
                        <ButtonComponent 
                            color="error"
                            variant="outlined" 
                            style={{marginRight: '0.5rem'}}
                            startIcon={<CancelOutlined />}
                            onClick={onCancelOrder}
                            isLoading={isCanceling}
                        >
                            Anuleaza
                        </ButtonComponent>
                    ),
                    canDisplay: () => {
                        return [OrderStatusEnum.Issued].includes(orderDetails?.status || OrderStatusEnum.Draft) &&
                            isEmpty(orderDetails?.awbs) 
                    }
                }
            ]
    
            return items.filter(f => f.canDisplay());
        },
        [
            isCanceling, 
            isIssuing, 
            onAwbGenerate, 
            onCancelOrder, 
            onIssueOrder, 
            orderDetails?.awbs, 
            orderDetails?.invoice, 
            orderDetails?.status, 
        ]
    ) 

    useEffect(
        () => {
            uiStore.updatePanel({
                subtitleComponent: !isEmpty(getActionsByOrderStatus()) ? () => (
                    <OrderDetailsHeaderComponent 
                        items={getActionsByOrderStatus()}
                    />
                ) : undefined,
            })
        },
        [orderDetails, uiStore.updatePanel, getActionsByOrderStatus, uiStore]
    )

    const totalDiscount = useMemo(
        (): number => {
            return (orderDetails?.lineItems ?? []).reduce(
                (prev: number, curr: LineItemType) => {
                    return prev + (curr.discount * curr.quantity);
                }, 
                0
            )
        },
        [orderDetails?.lineItems]
    )

    const customerSectionTitle = useMemo(
        (): string => {
            if (!orderDetails?.customer) return '';
            const customer = orderDetails?.customer as CustomerType;

            return [ customer.fullName, customer.email, customer.phoneNumber ].filter(f => f).join(', ');
        },
        [orderDetails?.customer]
    )

    const customerSectionDescription = useMemo(
        (): string => {
            if (!orderDetails?.customer) return '';
            const customer = orderDetails?.customer as CustomerType;

            return [ customer.company?.name, customer.company?.vatCode, customer.company?.regCode ].filter(f => f).join(', ');
        },
        [orderDetails?.customer]
    )


    /** define the return statement bellow */
    return (
        <OrderDetailsSummaryTabComponentStyled>

             <FormSectionComponent title="Client" variant='panel'>
                <PanelItemComponent
                        title={customerSectionTitle}
                        description={customerSectionDescription}
                        panelIcon={(
                            <PanelItemIconComponent 
                                color='rgb(34, 154, 22)' 
                                backgroundColor='rgba(84, 214, 44, 0.16)' 
                                icon={<Person fontSize='large' htmlColor='rgb(34, 154, 22)' />} 
                            />
                        )}
                        action={{
                            title: 'Vezi client',
                            onClick: onViewCustomer
                        }}
                    />     
            </FormSectionComponent>

            <FormSectionComponent variant="panel" title="Sumar comanda">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TableComponent
                            tableKey={TableTypeEnum.ViewOrderItems}
                            tableData={tableData}
                            viewType="read-only"
                            withoutPagination
                            withoutSearchBar
                            withoutSelect
                            withoutDenseSwitch
                            denseByDefault
                            withoutPersist
                            customRenderer={customRenderer}
                            canDisplayCustomRenderer={(row: OrderDetailsLineItemType) => !isEmpty(row.items)}
                            footerRenderer={(props) => <OrderTotalFooterComponent {...props} total={orderDetails?.totalPrice ?? 0} discountTotal={totalDiscount} />}
                        />
                    </Grid>
                </Grid>
            </FormSectionComponent>

            <FormSectionComponent title="Adrese" variant='panel'>
                <PanelItemComponent
                    title='Adresa livrare'
                    description={
                        [
                            orderDetails?.shippingAddress.city, 
                            orderDetails?.shippingAddress.county, 
                            orderDetails?.shippingAddress.postalCode, 
                            orderDetails?.shippingAddress.street, 
                            orderDetails?.shippingAddress.streetNumber, 
                            orderDetails?.shippingAddress.flat, 
                            orderDetails?.shippingAddress.building, 
                            orderDetails?.shippingAddress.floor, 
                            orderDetails?.shippingAddress.apartmentNumber
                        ]
                        .filter(f => f)
                        .join(', ')
                    }
                    panelIcon={(
                        <PanelItemIconComponent 
                            color='rgb(34, 154, 22)' 
                            backgroundColor='rgba(84, 214, 44, 0.16)' 
                            icon={<LocalShipping fontSize='large' htmlColor='rgb(34, 154, 22)' />} 
                        />
                    )}
                />
                <Divider style={{marginTop: '0.5rem', marginBottom: '0.5rem'}} />
                <PanelItemComponent
                    title='Adresa facturare'
                    description={
                        [
                            orderDetails?.billingAddress.city, 
                            orderDetails?.billingAddress.county, 
                            orderDetails?.billingAddress.otherDetails, 
                        ]
                        .filter(f => f)
                        .join(', ')
                    }
                    panelIcon={(
                        <PanelItemIconComponent 
                            color='rgb(34, 154, 22)' 
                            backgroundColor='rgba(84, 214, 44, 0.16)' 
                            icon={<Paid fontSize='large' htmlColor='rgb(34, 154, 22)' />} 
                        />
                    )}
                />
            </FormSectionComponent>

            <FormSectionComponent title="Status plata" variant="panel">
                <PanelItemComponent
                    title={orderDetails?.payment?.type ?? PaymentTypeEnum.Unpaid}
                    panelIcon={(
                        <PanelItemIconComponent 
                            color='gray' 
                            backgroundColor='rgba(0, 0, 0, 0.05)' 
                            icon={<Paid fontSize='large' htmlColor='rgba(0, 0, 0, 0.4)' />} 
                        />
                    )}
                    action={{   
                        title: 'Editeaza',
                        onClick: onPaymentEdit
                    }}
                />     
            </FormSectionComponent>

            {
                orderDetails?.invoice?
                    <FormSectionComponent title="Factura" variant="panel">
                        <PanelItemComponent
                            title={`${orderDetails?.invoice?.series} ${orderDetails?.invoice?.number}`}
                            description={getInvoiceDateText()}
                            panelIcon={(
                                <PanelItemIconComponent 
                                    color='rgb(12, 83, 183)' 
                                    backgroundColor='rgba(24, 144, 255, 0.16)' 
                                    icon={<Description fontSize='large' htmlColor='rgb(12, 83, 183)' />} 
                                />
                            )}
                            action={{
                                title: 'Vezi',
                                onClick: onViewInvoice
                            }}
                        />
                    </FormSectionComponent> :
                    ''
            }

            {
                !isEmpty(orderDetails?.awbs) ?
                    <FormSectionComponent title="Awb" variant="panel">
                        {
                            orderDetails?.awbs?.map((awb, index, items) => (
                                <div key={index}>
                                    <PanelItemComponent
                                        title={AwbTypeToDescription[awb.type]}
                                        description={`Numar: ${awb.number}`}
                                        panelIcon={(
                                            <PanelItemIconComponent 
                                                color='#e0730d' 
                                                backgroundColor='#fbecdd' 
                                                icon={<LocalPostOffice fontSize='large' htmlColor='#e0730d' />} 
                                            />
                                        )}
                                        action={{
                                            title: 'Vezi awb',
                                            onClick: () => onViewAwb(awb)
                                        }}
                                    />
                                    {
                                        index < items.length-1 ?
                                            <Divider style={{marginTop: '0.5rem', marginBottom: '0.5rem'}} /> :
                                            ''
                                    }
                                </div>
                                
                            ))
                        }
                        
                    </FormSectionComponent> :
                    ''
            }

        </OrderDetailsSummaryTabComponentStyled>
    )

}

export default OrderDetailsSummaryTabComponent;