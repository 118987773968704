import { Grid, InputAdornment } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { AddressTypeEnum } from '../../enums/address-type.enum';
import { PanelType } from '../../enums/panel-type.enum';
import { useStore } from '../../hooks/store.hook';
import { AddressType } from '../../types/address.type';
import Address from '../address/address.component';
import { StateFieldType } from '../../types/form.types';
import { SettingsCompanyType } from '../../types/settings.type';
import ButtonComponent from '../button/button.component';
import FormSectionComponent from '../form-section/form-section.component';
import TextFieldComponent from '../text-field/text-field.component';
import CompanySettingsComponentStyled from './company-settings.component.styled';

type StateType = {
    fields: {
        name: StateFieldType<string>;
        vatCode: StateFieldType<string>;
        regCode: StateFieldType<string>;

        contactDetailsFirstName: StateFieldType<string>;
        contactDetailsLastName: StateFieldType<string>;
        contactDetailsEmail: StateFieldType<string>;
        contactDetailsPhoneNumber: StateFieldType<string>;
        
        addressCounty: StateFieldType<string>;
        addressCity: StateFieldType<string>;
        addressPostalCode: StateFieldType<string>;
        addressStreet: StateFieldType<string>;
        addressStreetNumber: StateFieldType<string>;
        addressFlat: StateFieldType<string>;
        addressFloor: StateFieldType<string>;
        addressBuilding: StateFieldType<string>;
        addressApartmentNumber: StateFieldType<string>;
     

    };
    shouldDisplayError: boolean;
}

export type CompanySettingsComponentPropsType = {
    companyDetails?: Partial<SettingsCompanyType>,
    isLoading: boolean,
    onSave: (updatedCompanyDetails: SettingsCompanyType) => void 
}

const CompanySettingsComponent = ({
    companyDetails,
    isLoading,
    onSave = () => {}
}: CompanySettingsComponentPropsType) => {

    const [state, setState] = useState<StateType>({
        fields: {
            name: {
                value: '',
                isValid: false,
                errorMessage: 'Furnizati un nume al companiei valid'
            },
            vatCode: {
                value: '',
                isValid: false,
                errorMessage: 'Furnizati un cod fiscal valid'
            },
            regCode: {
                value: '',
                isValid: true,
                noValidation: true
            },
            contactDetailsFirstName: {
                value: '',
                isValid: true,
                noValidation: true
            },
            contactDetailsLastName: {
                value: '',
                isValid: true,
                noValidation: true
            },
            contactDetailsEmail: {
                value: '',
                isValid: true,
                noValidation: true
            },
            contactDetailsPhoneNumber: {
                value: '',
                isValid: false,
                errorMessage: 'Introduceti un numar de telefon valid'
            },
            addressCounty: {
                value: '',
                isValid: false,
                
            },
            addressCity: {
                value: '',
                isValid: false,
                
            },
            addressPostalCode: {
                value: '',
                isValid: false,
                
            },
            addressStreet: {
                value: '',
                isValid: false,
                
            },
            addressStreetNumber: {
                value: '',
                isValid: false,
                
            },
            addressFlat: {
                value: '',
                isValid: true,
                noValidation: true
            },
            addressFloor: {
                value: '',
                isValid: true,
                noValidation: true
            },
            addressBuilding: {
                value: '',
                isValid: true,
                noValidation: true
            },
            addressApartmentNumber: {
                value: '',
                isValid: true,
                noValidation: true
            },
        },
        shouldDisplayError: false
    })
    const [addressEdited, setAddresseEdited] = useState<boolean>(false)
    const uiStore = useStore('uiStore');

    const updateStateField = useCallback(
        (currentField: StateFieldType<any>, newValue: any): StateFieldType<any> => {
            return {
                ...currentField,
                isValid: (currentField.validator ? (currentField.validator as any)(newValue) : !!newValue) || 
                    currentField.noValidation,
                value: newValue
            }
        },
        []
    )

    const updateState = useCallback(
        <T extends keyof typeof state.fields>(field: T, newValue: any) => {
            setState(state => ({
                ...state,
                fields: {
                    ...state.fields,
                    [field]: updateStateField(state.fields[field], newValue)
                }
            }))
        },
        [state, updateStateField]
    )

    const updateCompanyDetails = useCallback(
        () => {

            setState(() => ({
                ...state,
                shouldDisplayError: true
            }))

            const isNotValid = Object.values(state.fields).some(field => !field.isValid);
            if (isNotValid) return;

            const updatedCompanyDetails: SettingsCompanyType = {
                name: state.fields.name.value,
                vatCode: state.fields.vatCode.value,
                regCode: state.fields.regCode.value,
                contactDetails: {
                    firstName: state.fields.contactDetailsFirstName.value,
                    lastName: state.fields.contactDetailsLastName.value,
                    email: state.fields.contactDetailsEmail.value,
                    phoneNumber: state.fields.contactDetailsPhoneNumber.value,
                },
                address: {
                    city: state.fields.addressCity.value,
                    county: state.fields.addressCounty.value,
                    postalCode: state.fields.addressPostalCode.value,
                    street: state.fields.addressStreet.value,
                    streetNumber: state.fields.addressPostalCode.value,
                    flat: state.fields.addressFlat.value,
                    floor: state.fields.addressFloor.value,
                    building: state.fields.addressBuilding.value,
                    apartmentNumber: state.fields.addressApartmentNumber.value,
                }
            }

            onSave(updatedCompanyDetails);
        },
        [onSave, state]
    )

    useEffect(
        () => {
            if (!companyDetails) return;
            setState(state => ({
                ...state,
                fields: {
                    name: updateStateField(state.fields.name, companyDetails.name ?? ''),
                    vatCode: updateStateField(state.fields.vatCode, companyDetails.vatCode ?? ''),
                    regCode: updateStateField(state.fields.regCode, companyDetails.regCode ?? ''),
                    contactDetailsFirstName: updateStateField(state.fields.contactDetailsFirstName, companyDetails.contactDetails?.firstName ?? ''),
                    contactDetailsLastName: updateStateField(state.fields.contactDetailsLastName, companyDetails.contactDetails?.lastName ?? ''),
                    contactDetailsEmail: updateStateField(state.fields.contactDetailsEmail, companyDetails.contactDetails?.email ?? ''),
                    contactDetailsPhoneNumber: updateStateField(state.fields.contactDetailsPhoneNumber, companyDetails.contactDetails?.phoneNumber ?? ''),
                    addressCity: updateStateField(state.fields.addressCity, companyDetails.address?.city ?? ''),
                    addressCounty: updateStateField(state.fields.addressCounty, companyDetails.address?.county ?? ''),
                    addressPostalCode: updateStateField(state.fields.addressPostalCode, companyDetails?.address?.postalCode ?? ''),
                    addressStreet: updateStateField(state.fields.addressStreet, companyDetails?.address?.street ?? ''),
                    addressStreetNumber: updateStateField(state.fields.addressStreetNumber, companyDetails?.address?.streetNumber ?? ''),
                    addressFlat: updateStateField(state.fields.addressFlat, companyDetails?.address?.flat ?? ''),
                    addressBuilding: updateStateField(state.fields.addressBuilding, companyDetails?.address?.floor ?? ''),
                    addressFloor: updateStateField(state.fields.addressFloor, companyDetails?.address?.building ?? ''),
                    addressApartmentNumber: updateStateField(state.fields.addressApartmentNumber, companyDetails?.address?.apartmentNumber ?? ''),
                    
                }
            }))

            if(addressEdited){
                updateCompanyDetails()
                setAddresseEdited( () => false)
            }
        },
        [companyDetails, addressEdited, updateCompanyDetails, updateStateField]
    )

    const updateAddressCompany = useCallback ( 
        () => {
            uiStore.openPanel({
                key: PanelType.EditAddress,
                title: "Editeaza adresa",
                component: <Address  type={AddressTypeEnum.Normal} editInfo={companyDetails?.address} />,
                onDismiss: (data: AddressType) => {
                    if (!data) return;

                    setState(state => ({
                        ...state,
                        fields: {
                            ...state.fields,
                            addressCity: updateStateField(state.fields.addressCity, data.city ),
                            addressCounty: updateStateField(state.fields.addressCounty, data.county),
                            addressPostalCode: updateStateField(state.fields.addressPostalCode, data.postalCode ),
                            addressStreet: updateStateField(state.fields.addressStreet, data.street ),
                            addressStreetNumber: updateStateField(state.fields.addressStreetNumber, data.streetNumber ),
                            addressFlat: updateStateField(state.fields.addressFlat, data.flat ),
                            addressFloor: updateStateField(state.fields.addressFloor, data.floor ),
                            addressApartmentNumber: updateStateField(state.fields.addressApartmentNumber, data.apartmentNumber ),
                            addressBuilding: updateStateField(state.fields.addressBuilding, data.building ),
                            
                        }
                    }))

                    setAddresseEdited( () => true)
        
                }
            })
        },
        [companyDetails, uiStore, updateStateField]
    )

    
    /** define the return statement bellow */
    return (
        <CompanySettingsComponentStyled>

            <FormSectionComponent title="Detalii generale">
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextFieldComponent
                            label="Nume companie" 
                            variant="outlined" 
                            fullWidth={true}
                            value={state.fields.name.value}
                            error={state.shouldDisplayError && !state.fields.name.isValid}
                            helperText={state.shouldDisplayError && !state.fields.name.isValid && state.fields.name.errorMessage}
                            onTextChange={e => updateState('name', e)}
                        />
                    </Grid>

                    <Grid item xs={6}></Grid>

                    <Grid item xs={6}>
                        <TextFieldComponent
                            label="Cod fiscal" 
                            variant="outlined" 
                            fullWidth={true}
                            value={state.fields.vatCode.value}
                            error={state.shouldDisplayError && !state.fields.vatCode.isValid}
                            helperText={state.shouldDisplayError && !state.fields.vatCode.isValid && state.fields.vatCode.errorMessage}
                            onTextChange={e => updateState('vatCode', e)}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextFieldComponent
                            label="Numar Registrul Comertului" 
                            variant="outlined" 
                            fullWidth={true}
                            value={state.fields.regCode.value}
                            error={state.shouldDisplayError && !state.fields.regCode.isValid}
                            helperText={state.shouldDisplayError && !state.fields.regCode.isValid && state.fields.regCode.errorMessage}
                            onTextChange={e => updateState('regCode', e)}
                        />
                    </Grid>
                </Grid>

                <div className="button-container">
                    <ButtonComponent onClick={updateCompanyDetails} isLoading={isLoading}>
                        Salveaza
                    </ButtonComponent>
                </div>
            </FormSectionComponent>

            <FormSectionComponent title="Detalii de contact">
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <TextFieldComponent
                            label="Prenume" 
                            variant="outlined" 
                            fullWidth={true}
                            value={state.fields.contactDetailsFirstName.value}
                            error={state.shouldDisplayError && !state.fields.contactDetailsFirstName.isValid}
                            helperText={state.shouldDisplayError && !state.fields.contactDetailsFirstName.isValid && state.fields.contactDetailsFirstName.errorMessage}
                            onTextChange={e => updateState('contactDetailsFirstName', e)}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <TextFieldComponent
                            label="Nume" 
                            variant="outlined" 
                            fullWidth={true}
                            value={state.fields.contactDetailsLastName.value}
                            error={state.shouldDisplayError && !state.fields.contactDetailsLastName.isValid}
                            helperText={state.shouldDisplayError && !state.fields.contactDetailsLastName.isValid && state.fields.contactDetailsLastName.errorMessage}
                            onTextChange={e => updateState('contactDetailsLastName', e)}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <TextFieldComponent
                            label="Numar de telefon" 
                            variant="outlined" 
                            fullWidth={true}
                            value={state.fields.contactDetailsPhoneNumber.value}
                            error={state.shouldDisplayError && !state.fields.contactDetailsPhoneNumber.isValid}
                            helperText={state.shouldDisplayError && !state.fields.contactDetailsPhoneNumber.isValid && state.fields.contactDetailsPhoneNumber.errorMessage}
                            onTextChange={e => updateState('contactDetailsPhoneNumber', e)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">+4</InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                </Grid>

                <div className="button-container">
                    <ButtonComponent onClick={updateCompanyDetails} isLoading={isLoading}>
                        Salveaza
                    </ButtonComponent>
                </div>
            </FormSectionComponent>

            <FormSectionComponent title="Adresa">
                {
                    [
                        companyDetails?.address?.city, 
                        companyDetails?.address?.county, 
                        companyDetails?.address?.postalCode, 
                        companyDetails?.address?.street, 
                        companyDetails?.address?.streetNumber, 
                        companyDetails?.address?.flat, 
                        companyDetails?.address?.building, 
                        companyDetails?.address?.floor, 
                        companyDetails?.address?.apartmentNumber
                    ]
                    .filter(f => f)
                    .join(', ')
                }


                <div className="button-container">
                    <ButtonComponent onClick={updateAddressCompany} isLoading={isLoading}>
                        Editare
                    </ButtonComponent>
                </div>
            </FormSectionComponent>

        </CompanySettingsComponentStyled>
    )

}

export default CompanySettingsComponent;